import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import { BsCheck, BsFillClipboard2DataFill } from 'react-icons/bs';
import { FaBarcode, FaGripLines, FaReceipt, FaWarehouse } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import * as MdIcons from 'react-icons/md';
import { MdInventory } from 'react-icons/md';
import { PiSplitVertical } from 'react-icons/pi';
import { SiMockserviceworker } from 'react-icons/si';

const Icons = {
    Archive: BiIcons.BiSolidTrashAlt,
    ArrowNext: BiIcons.BiRightArrowAlt,
    ArrowPrev: BiIcons.BiLeftArrowAlt,
    Barcode: FaBarcode,
    Calendar: BiIcons.BiCalendar,
    CancelOrder: BiIcons.BiWindowClose,
    CaretDown: AiFillCaretDown,
    CaretUp: AiFillCaretUp,
    Check: BiIcons.BiCheck,
    CheckPartial: MdIcons.MdSquare,
    ChevronLeft: BiIcons.BiChevronLeft,
    ChevronDown: BiIcons.BiChevronDown,
    ChevronRight: BiIcons.BiChevronRight,
    ChevronUp: BiIcons.BiChevronUp,
    Clear: BiIcons.BiSolidXCircle,
    Close: IoClose,
    Code: BiIcons.BiCodeCurly,
    Copy: BiIcons.BiCopy,
    Customers: BiIcons.BiSolidFace,
    Dashboard: MdIcons.MdBusiness,
    Documents: BiIcons.BiFolder,
    Dollar: BiIcons.BiDollar,
    Download: BiIcons.BiSolidDownload,
    DragHandle: FaGripLines,
    Edit: BiIcons.BiSolidPencil,
    EmployeesAndRoles: MdIcons.MdFolderShared,
    Error: BiIcons.BiSolidErrorCircle,
    Export: BiIcons.BiSolidCloudDownload,
    ExternalLink: BiIcons.BiLinkExternal,
    Filter: BiIcons.BiFilterAlt,
    History: BiIcons.BiHistory,
    Home: BiIcons.BiSolidHome,
    Inventory: MdInventory,
    InventoryGroup: BiIcons.BiSolidCategory,
    InventoryReceipt: BiIcons.BiSolidArchiveIn,
    List: BiIcons.BiListUl,
    Locations: FaWarehouse,
    Locked: BiIcons.BiLockAlt,
    Logout: BiIcons.BiLogOut,
    MenuDots: BiIcons.BiDotsVerticalRounded,
    MenuLines: BiIcons.BiMenu,
    Minus: BiIcons.BiMinus,
    Mock: SiMockserviceworker,
    OrderManagement: MdIcons.MdEqualizer,
    OrderSplit: PiSplitVertical,
    Package: BiIcons.BiSolidPackage,
    Percent: MdIcons.MdOutlinePercent,
    Person: BiIcons.BiUser,
    Plus: BiIcons.BiPlus,
    Print: BiIcons.BiSolidPrinter,
    ProductPricing: MdIcons.MdPermDataSetting,
    Refresh: BiIcons.BiRefresh,
    Reports: MdIcons.MdTimeline,
    SadFace: BiIcons.BiSad,
    SalesOrder: BsFillClipboard2DataFill, // BiIcons.BiSolidBarChartAlt2,
    SalesOrderGroup: BiIcons.BiSolidBarChartAlt2,
    SalesOrderLabel: MdIcons.MdLabelOutline,
    SalesOrderSummary: MdIcons.MdOutlineSummarize,
    SalesOrderWorksheet: BiIcons.BiSpreadsheet,
    Schedule: BiIcons.BiSolidCalendar,
    ScheduleEdit: BiIcons.BiCalendarEdit,
    Search: BiIcons.BiSearch,
    Settings: MdIcons.MdSettings,
    Shipment: BiIcons.BiSolidTruck,
    StepComplete: BsCheck,
    StepPause: BiIcons.BiPause,
    StepReset: BiIcons.BiReset,
    StepSkip: BiIcons.BiSkipNext,
    StepStart: BiIcons.BiPlay,
    Stocktake: BiIcons.BiSolidCalendarEvent,
    StocktakeReview: BiIcons.BiListCheck,
    Tasks: MdIcons.MdTask,
    Undo: BiIcons.BiUndo,
    UpdateAvailable: BiIcons.BiMessageSquareError,
    Upload: BiIcons.BiUpload,
    WorkOrder: FaReceipt,
    Workstation: BiIcons.BiSolidFactory,
} as const;

export default Icons;
