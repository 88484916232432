import Icons from 'Icons';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import UdfEdit from 'components/UdfEdit/UdfEdit';
import inventoryApi from 'features/inventory/inventory.api';
import { InventoryGroupDetail } from 'features/inventory/models/InventoryGroupDetail';
import { AttributeField } from 'models/AttributeField';
import { UserDefinedField } from 'models/UserDefinedField';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import './InventoryGroupDetailModal.scss';

export default function InventoryGroupDetailModal({
    model,
    isNew = false,
    udfs = [],
    attributes,
    isLoading = false,
    isError = false,
    onSave,
    isSaving,
    close,
}: {
    model?: InventoryGroupDetail;
    isNew?: boolean;
    udfs?: UserDefinedField[];
    attributes?: AttributeField[];
    isLoading?: boolean;
    isError?: boolean;
    onSave?: (model: NonNullable<InventoryGroupDetail>) => Promise<any | void>;
    isSaving?: boolean;
    close?: () => void;
}) {
    const [archiveQuery] = inventoryApi.useInventoryGroupArchiveMutation();

    const dialogManager = useDialogManager();

    const handleArchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            message: 'Are you sure you want to archive this inventory group?',
            acceptLabel: 'Yes, archive it',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(archiveQuery(model.id).unwrap());
            close?.();
        }
    }, [close, archiveQuery, dialogManager, model]);

    return (
        <MyEditModal
            className="InventoryGroupDetailModal"
            title={isNew ? 'Add Inventory Group' : 'Inventory Group'}
            close={close}
            isLoading={isLoading}
            isError={isError}
            model={model}
            onSave={onSave}
            isSaving={isSaving}
            readonly={false}
            editImmediately={isNew}
            headerMenuItems={
                isNew
                    ? undefined
                    : [
                          {
                              label: 'Archive',
                              IconLeft: Icons.Archive,
                              onClick: handleArchive,
                          },
                      ]
            }
        >
            {({ editModel, isEditing, updateField }) => (
                <>
                    <PropertyEditText
                        label="Name"
                        value={editModel.name}
                        onChange={val => updateField({ name: val })}
                        readonly={!isEditing}
                        disabled={isSaving}
                        validationRequired={true}
                    />

                    {/* User defined fields */}
                    {udfs?.map(udf => (
                        <UdfEdit
                            key={udf.id}
                            udf={udf}
                            value={editModel.userDefinedFields[udf.uniqueKey]}
                            onChange={val =>
                                updateField({
                                    userDefinedFields: { [udf.uniqueKey]: val },
                                })
                            }
                            readonly={!isEditing}
                            disabled={isSaving}
                        />
                    ))}

                    {/* Attributes */}
                    <PropertyDisplay
                        label="Attributes"
                        value={
                            editModel.context.attributes.length ? (
                                <>
                                    {editModel.context.attributes.map(attr => (
                                        <div key={attr.id}>
                                            <div>
                                                {attr.name}{' '}
                                                {attributes?.find(a => a.id === attr.id)
                                                    ? '✅'
                                                    : ''}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : undefined
                        }
                    />
                </>
            )}
        </MyEditModal>
    );
}
