import { DateTime } from 'luxon';
import { dateFilterOptionsAny, DateFilterValuesPast } from 'models/DateFilterOption';
import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { api, ApiTagType } from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import z from 'zod';
import {
    DealerDocumentsResult,
    DealerDocumentsResultSchema,
} from '../documents/models/DealerDocumentsResult';
import { DocumentUploadResultDocument } from '../documents/models/DocumentUploadResult';
import {
    ManufacturerDocumentsResult,
    ManufacturerDocumentsResultSchema,
} from '../documents/models/ManufacturerDocumentsResult';
import { SalesItem } from './models/SalesItem';
import {
    SalesItemHistoryResult,
    SalesItemHistoryResultSchema,
} from './models/SalesItemHistoryResult';
import { SalesItemLabelConfig, SalesItemLabelConfigSchema } from './models/SalesItemLabelConfig';
import { SalesItemOptions, SalesItemOptionsSchema } from './models/SalesItemOptions';
import { SalesOrder, SalesOrderSchema } from './models/SalesOrder';
import { SalesOrderDetail, SalesOrderDetailSchema } from './models/SalesOrderDetail';
import { SalesOrderGenerateWorksheetsResultOldSchema } from './models/SalesOrderGenerateWorksheetsResultOld';
import {
    SalesOrderHistoryResult,
    SalesOrderHistoryResultSchema,
} from './models/SalesOrderHistoryResult';
import { SalesOrderLineStatus, SalesOrderLineStatusOldSchema } from './models/SalesOrderLineStatus';
import { SalesOrderSplitResult, SalesOrderSplitResultSchema } from './models/SalesOrderSplitResult';
import { SalesOrderStatus, SalesOrderStatusOldSchema } from './models/SalesOrderStatus';
import { SalesOrderWorkItem, SalesOrderWorkItemSchema } from './models/SalesOrderWorkItem';
import {
    SalesOrderWorkItemsResult,
    SalesOrderWorkItemsResultSchema,
} from './models/SalesOrderWorkItemsResult';
import { SalesOrderWorksheet, SalesOrderWorksheetSchema } from './models/SalesOrderWorksheet';

const salesOrderStatusResultSchema = z.object({
    data: z.array(SalesOrderStatusOldSchema),
});

const salesOrderLineStatusResultSchema = z.object({
    data: z.array(SalesOrderLineStatusOldSchema),
});

export type SalesOrderListParams = DynamicQueryParams<{
    search: string;
    dateReceived: DateFilterValuesPast | null;
    statusId: number | null;
    isArchived: boolean | null;
}>;

export type SalesOrderHistoryParams = DynamicQueryParams<{
    manufacturerOrderId: number;
}>;

export type SalesItemHistoryParams = DynamicQueryParams<{
    id: number;
}>;

const salesApi = api.injectEndpoints({
    endpoints: build => ({
        salesOrderList: build.query<{ data: SalesOrder[]; total: number }, SalesOrderListParams>({
            query: args => {
                const dateFilter = dateFilterOptionsAny.find(
                    o => o.value === args.criteria.dateReceived,
                );
                const dateFrom = dateFilter?.minDate.toISO({ includeOffset: false }) ?? null;
                const dateTo = dateFilter?.maxDate.toISO({ includeOffset: false }) ?? null;
                return {
                    url: `/salesorder`,
                    method: 'POST',
                    data: {
                        search: args.criteria.search,
                        dateFrom,
                        dateTo,
                        status: args.criteria.statusId || undefined,
                        isArchived: args.criteria.isArchived,
                        meta: {
                            skip: args.paging.skip,
                            limit: args.paging.limit,
                            sortBy: args.sort?.propertyKey,
                            sortDir: args.sort?.direction,
                        },
                    },
                };
            },
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesOrderSchema),
                    total: z.number(),
                });
                return schema.parse(response);
            },
            providesTags: [ApiTagType.SalesOrderList],
        }),

        salesOrderDetail: build.query<SalesOrderDetail, string>({
            query: id => ({
                url: `/salesorder/${id}`,
                method: 'GET',
            }),
            transformResponse: result => SalesOrderDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.SalesOrderList, id }],
        }),

        salesItemOptions: build.query<SalesItemOptions, number>({
            query: itemId => ({
                url: `/old/manufacturer-order-lines/${itemId}/inventory-options`,
                method: 'GET',
            }),
            transformResponse: result => {
                const schema = z.object({
                    data: SalesItemOptionsSchema,
                });
                return schema.parse(result).data;
            },
            providesTags: (res, err, itemId) => [
                { type: ApiTagType.SalesItemInventoryOptions, id: itemId },
            ],
        }),

        /** Load sales order status values */
        salesOrderStatuses: build.query<SalesOrderStatus[], void>({
            query: () => ({
                url: `/old/manufacturer-order-statuses/select?all=true`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const arr = salesOrderStatusResultSchema.parse(result).data;
                arr?.sort((a, b) => a.sortOrder - b.sortOrder);
                return arr;
            },
            providesTags: [ApiTagType.SalesOrderStatuses],
        }),

        /** Load order item status values */
        salesItemStatuses: build.query<SalesOrderLineStatus[], void>({
            query: () => ({
                url: `/old/manufacturer-order-line-statuses/select?all=true`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const arr = salesOrderLineStatusResultSchema.parse(result).data;
                arr?.sort((a, b) => a.sortOrder - b.sortOrder);
                return arr;
            },
            providesTags: [ApiTagType.SalesOrderLineStatuses],
        }),

        /** Get history records for sales order */
        salesOrderHistory: build.query<SalesOrderHistoryResult, SalesOrderHistoryParams>({
            query: params => ({
                url:
                    `/old/trackers?` +
                    `arguments%5Btype%5D%5B%5D=order-update` +
                    `&arguments%5Btype%5D%5B%5D=order-line-split` +
                    `&arguments%5Btype%5D%5B%5D=order-dl` +
                    `&arguments%5Bmodel%5D=ManufacturerOrder` +
                    `&arguments%5Bid%5D=${params.criteria.manufacturerOrderId}` +
                    `&arguments%5Bevent_item%5D=` +
                    `&arguments%5Bfilters%5D%5Bsearch_text%5D=` +
                    `&pagination%5Boffset%5D=${params.paging.skip}` +
                    `&pagination%5Blimit%5D=${params.paging.limit}`,
                method: 'GET',
            }),
            transformResponse: result => SalesOrderHistoryResultSchema.parse(result),
            providesTags: (res, err, params) => [
                { type: ApiTagType.SalesOrderHistory, id: params.criteria.manufacturerOrderId },
            ],
        }),

        /** Get history records for order item */
        salesItemHistory: build.query<SalesItemHistoryResult, SalesItemHistoryParams>({
            query: params => ({
                url:
                    `/old/trackers?` +
                    `arguments%5Btype%5D%5B%5D=order-line` +
                    `&arguments%5Btype%5D%5B%5D=order-line-dl` +
                    `&arguments%5Bmodel%5D=ManufacturerOrderLine` +
                    `&arguments%5Bid%5D=${params.criteria.id}` +
                    `&arguments%5Bevent_item%5D=` +
                    `&arguments%5Bfilters%5D%5Bsearch_text%5D=` +
                    `&pagination%5Boffset%5D=${params.paging.skip}` +
                    `&pagination%5Blimit%5D=${params.paging.limit}`,
                method: 'GET',
            }),
            transformResponse: result => SalesItemHistoryResultSchema.parse(result),
            providesTags: (res, err, params) => [
                { type: ApiTagType.SalesItemHistory, id: params.criteria.id },
            ],
        }),

        /** Get sales order documents (uploaded by manufacturer) */
        salesOrderDocuments: build.query<ManufacturerDocumentsResult, number>({
            query: manufacturerOrderId => ({
                url: `/old/orders/${manufacturerOrderId}/documents`,
                method: 'GET',
            }),
            transformResponse: result => ManufacturerDocumentsResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.SalesOrderDocuments, id }],
        }),

        /** Get sales order documents (uploaded by dealer) */
        salesOrderDealerDocuments: build.query<DealerDocumentsResult, number>({
            query: manufacturerOrderId => ({
                url: `/old/orders/${manufacturerOrderId}/dealer-documents`,
                method: 'GET',
            }),
            transformResponse: result => DealerDocumentsResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.SalesOrderDocuments, id }],
        }),

        /** Attach an uploaded document to a sales order */
        salesOrderDocumentAttach: build.mutation<
            void,
            {
                document: DocumentUploadResultDocument;
                manufacturerOrderId: number;
            }
        >({
            query: args => ({
                url: `/old/orders/${args.manufacturerOrderId}/documents`,
                method: 'POST',
                data: {
                    data: [args.document],
                    directUpload: true,
                    is_image_upload: true, // always true?
                    model: 'Order',
                    model_id: '',
                    type: null,
                },
            }),
            invalidatesTags: (req, res, args) => [
                { type: ApiTagType.SalesOrderDocuments, id: args.manufacturerOrderId },
            ],
        }),

        /** Archive document */
        salesOrderDocumentArchive: build.mutation<
            void,
            {
                manufacturerOrderId: number;
                documentId: number;
            }
        >({
            query: args => ({
                url: `/old/orders/${args.manufacturerOrderId}/documents/${args.documentId}`,
                method: 'POST',
                data: {
                    model: 'Order',
                    model_id: '',
                    _method: 'DELETE',
                },
            }),
            invalidatesTags: (req, res, args) => [
                { type: ApiTagType.SalesOrderDocuments, id: args.manufacturerOrderId },
            ],
        }),

        /** Sales Order generate labels - Old version - to be removed soon */
        salesOrderGenerateLabelsOld: build.mutation<
            string,
            { order: SalesOrderDetail; labelIds: number[]; copies: number | null }
        >({
            query: ({ order, copies, labelIds }) => {
                // generate a uuid to be used in all items
                const uuid = uuidv4();
                return {
                    url: `/old/orders/ordering/label/generate-order-labels`,
                    method: 'POST',
                    data: {
                        items: order.context.orderLines.map((item, index) => ({
                            type: 'product',
                            quantity: item.quantity,
                            copies,
                            manufacturer_order_id: order.legacyId,
                            manufacturer_order_line_id: item.id,
                            purchase_order_id: null,
                            order_id: item.context.orderWindow.orderId,
                            order_window_id: item.orderWindowId,
                            order_window_product_id: item.orderWindowProductId,
                            brand_id: order.context.customerPurchaseOrder.brandId,
                            category_id: order.context.customerPurchaseOrder.categoryId,
                            // product_measurement_other_id: '',
                            label_type: 'standard',
                            split_id: order.context.customerPurchaseOrder.splitId,
                            uuid,
                            total: order.context.orderLines.length,
                            count: index + 1,
                            print_all: true,
                        })),
                        labelIds,
                    },
                };
            },
            transformResponse: result => {
                if (typeof result === 'string') {
                    // BE just returns a string if something goes wrong
                    // This should be changed in the BE to make it nicer
                    throw new Error(result);
                }
                const schema = z.object({
                    file: z.string(),
                });
                return schema.parse(result).file;
            },
        }),

        /** Sales Order generate labels - New version */
        salesOrderGenerateLabelsNew: build.mutation<
            string,
            {
                order: SalesOrder;
                item?: SalesItem;
                labelIds: number[];
                copies: number | null;
            }
        >({
            query: ({ order, item, labelIds, copies }) => ({
                url: `/salesorder/${order.legacyId}/labels`,
                method: 'POST',
                data: {
                    copies,
                    labelIds,
                    salesOrderLineIds: item ? [item.id] : null,
                },
            }),
            transformResponse: result => {
                if (typeof result === 'string') {
                    // BE just returns a string if something goes wrong
                    // This should be changed in the BE to make it nicer
                    throw new Error(result);
                }
                const schema = z.object({
                    file: z.string(),
                });
                return schema.parse(result).file;
            },
        }),

        salesOrderLabelConfig: build.query<SalesItemLabelConfig[], number>({
            query: orderId => ({
                url: `/old/labels/label-config/sales-order/${orderId}`,
                method: 'GET',
            }),
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesItemLabelConfigSchema),
                });
                return schema.parse(response).data;
            },
        }),

        salesItemLabelConfig: build.query<SalesItemLabelConfig[], number>({
            query: salesItemId => ({
                url: `/old/labels/label-config/sales-order-line/${salesItemId}`,
                method: 'GET',
            }),
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesItemLabelConfigSchema),
                });
                return schema.parse(response).data;
            },
        }),

        salesItemWorkflowStepLabelConfig: build.query<
            SalesItemLabelConfig[],
            { salesItemId: string; workflowStepId: string }
        >({
            query: ({ salesItemId, workflowStepId }) => ({
                url: `/old/labels/label-config/sales-order-line/${salesItemId}/workflow-step/${workflowStepId}`,
                method: 'GET',
            }),
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesItemLabelConfigSchema),
                });
                return schema.parse(response).data;
            },
        }),

        /** Order Item generate labels - Old version - to be removed soon
         * Replaced by salesOrderGenerateLabelsNew with optional itemId param
         */
        salesItemGenerateLabelsOld: build.mutation<
            string,
            {
                order: SalesOrderDetail;
                line: SalesItem;
                labelIds: number[];
                copies: number | null;
            }
        >({
            query: ({ order, line, labelIds, copies }) => {
                // generate a uuid to be used in all items
                const uuid = uuidv4();

                return {
                    url: `/old/orders/ordering/label/generate-order-labels`,
                    method: 'POST',
                    data: {
                        brand_id: order.context.customerPurchaseOrder.brandId,
                        category_id: order.context.customerPurchaseOrder.categoryId,
                        count: 1,
                        copies,
                        first_run: true,
                        label_type: 'standard',
                        manufacturer_order_id: order.legacyId,
                        manufacturer_order_line_id: line.id,
                        labelIds,
                        order_id: line.context.orderWindow.orderId,
                        order_window_id: line.orderWindowId,
                        order_window_product_id: line.orderWindowProductId,
                        print_all: true,
                        // product_measurement_other_id:"",
                        purchase_order_id: null,
                        quantity: line.quantity,
                        split_id: order.context.customerPurchaseOrder.splitId,
                        total: order.context.orderLines.length,
                        type: 'product',
                        uuid,
                    },
                };
            },
            transformResponse: result => {
                if (typeof result === 'string') {
                    // BE just returns a string if something goes wrong
                    // This should be changed in the BE to make it nicer
                    throw new Error(result);
                }
                const schema = z.object({
                    file: z.string(),
                });
                return schema.parse(result).file;
            },
        }),

        /** Sales order generate worksheets */
        salesOrderGenerateWorksheets: build.mutation<SalesOrderWorksheet[], SalesOrder>({
            query: order => ({
                url: `/salesorder/${order.legacyId}/worksheets`,
                method: 'POST',
            }),
            transformResponse: data => {
                const schema = z.array(SalesOrderWorksheetSchema);
                return schema.parse(data);
            },
        }),

        /** Order Item generate worksheets
         * TODO implement new route for this to match the SalesOrderGenerateWorksheets route
         */
        salesItemGenerateWorksheets: build.mutation<SalesOrderWorksheet[], SalesItem>({
            query: item => ({
                url: `/old/manufacturer-order-lines/generate/${item.id}`,
                method: 'POST',
                data: {
                    id: item.id,
                    manufacturer_order_id: item.manufacturerOrderId,
                    line_number: item.lineNumber,
                },
            }),
            transformResponse: data => {
                const resultOld = SalesOrderGenerateWorksheetsResultOldSchema.parse(data);
                const resultNew: SalesOrderWorksheet[] = Object.entries(resultOld.data.xls).map(
                    ([key, val]) => ({
                        name: val.error_message === undefined ? val.name : key,
                        url: val.error_message === undefined ? val.url : undefined,
                        errorMessage: val.error_message,
                    }),
                );
                return resultNew;
            },
        }),

        /** Write to Sales order log */
        salesOrderWriteLog: build.mutation<
            void,
            {
                manufacturerOrderId: number;
                manufacturerReference: string;
                action: string;
                download: boolean;
                downloadType: string;
            }
        >({
            query: args => ({
                url: `/old/manufacturer-orders/${args.manufacturerOrderId}`,
                method: 'POST',
                data: {
                    action: args.action,
                    download: args.download,
                    download_type: args.downloadType,
                    manufacturer_reference: args.manufacturerReference,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: (req, res, params) => [
                { type: ApiTagType.SalesOrderHistory, id: params.manufacturerOrderId },
            ],
        }),

        /** Write to order item log */
        salesItemWriteLog: build.mutation<
            void,
            {
                id: number;
                itemNumber: number;
                action: string;
                download: boolean;
                downloadType: string;
            }
        >({
            query: args => ({
                url: `/old/manufacturer-order-lines/${args.id}`,
                method: 'POST',
                data: {
                    action: args.action,
                    download: args.download,
                    download_type: args.downloadType,
                    line_number: args.itemNumber,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: (req, res, params) => [
                { type: ApiTagType.SalesItemHistory, id: params.id },
            ],
        }),

        /** Update sales order */
        salesOrderUpdate: build.mutation<void, SalesOrder>({
            query: order => ({
                url: `/old/manufacturer-orders/${order.legacyId}`,
                method: 'POST',
                data: {
                    action: 'order_status_id',
                    customer_notes: order.customerNotes,
                    notes: order.notes,
                    eta: order.eta,
                    order_status_id: order.orderStatusId,
                    total_freight_override: order.totalFreightOverride,
                    tracking_information: order.trackingInformation,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: (req, res, order) => [
                ApiTagType.SalesOrderList,
                { type: ApiTagType.SalesOrderHistory, id: order.id },
            ],
        }),

        /** Update sales item
         * if using for bulk update then set invalidateTags to false
         * and manually invalidate when you're done */
        salesItemUpdate: build.mutation<
            void,
            {
                item: SalesItem;
                invalidateTags?: boolean;
            }
        >({
            query: ({ item }) => ({
                url: `/old/manufacturer-order-lines/${item.id}`,
                method: 'POST',
                data: {
                    action: 'order_line_status_id',
                    notes: item.notes,
                    customer_notes: item.customerNotes,
                    order_line_status_id: item.orderLineStatusId,
                    quantity: item.quantity,
                    unit_cost_price: item.unitCostPrice,
                    unit_sell_price: item.unitSellPrice,
                    unit_tax: item.unitTax,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: (req, res, { item, invalidateTags = true }) =>
                invalidateTags
                    ? [
                          ApiTagType.SalesOrderList,
                          { type: ApiTagType.SalesItemHistory, id: item.id },
                          { type: ApiTagType.SalesOrderWorkItems, id: item.manufacturerOrderId },
                          { type: ApiTagType.SalesItemWorkItemSteps, id: item.id },
                      ]
                    : [],
        }),

        salesOrderResyncProductData: build.mutation<void, SalesOrder>({
            query: order => ({
                url: `/salesorder/old/${order.legacyId}/refreshkmats`,
                method: 'POST',
            }),
            invalidatesTags: [ApiTagType.SalesOrderList],
        }),

        /** Archive sales order */
        salesOrderArchive: build.mutation<void, SalesOrder>({
            query: order => ({
                url: `/old/manufacturer-orders/archive`,
                method: 'POST',
                data: {
                    archived_date: DateTime.now().toUTC().toISO(), // .toFormat('yyyy-MM-dd HH:mm:ss'),
                    ids: [order.legacyId],
                },
            }),
            invalidatesTags: (req, res, order) => [
                ApiTagType.SalesOrderList,
                { type: ApiTagType.SalesOrderHistory, id: order.id },
            ],
        }),

        salesOrderUnarchive: build.mutation<void, SalesOrder>({
            query: order => ({
                url: `/old/manufacturer-orders/unarchive`,
                method: 'POST',
                data: {
                    ids: [order.legacyId],
                },
            }),
            invalidatesTags: (req, res, order) => [
                ApiTagType.SalesOrderList,
                { type: ApiTagType.SalesOrderHistory, id: order.id },
            ],
        }),

        /** Cancel sales order */
        salesOrderCancel: build.mutation<void, { orderId: string; statusId: number }>({
            query: args => ({
                url: `/salesorder/${args.orderId}/cancel`,
                method: 'POST',
                data: {
                    orderStatusId: args.statusId,
                },
            }),
            invalidatesTags: (req, res, args) => [
                ApiTagType.SalesOrderList,
                { type: ApiTagType.SalesOrderHistory, id: args.orderId },
                ApiTagType.SalesItemWorkItemSteps,
                { type: ApiTagType.SalesOrderWorkItems, id: args.orderId },
            ],
        }),

        /** Get work item statuses by sales order id */
        salesOrderWorkItems: build.query<SalesOrderWorkItemsResult, number>({
            query: salesOrderId => ({
                url: `/salesorder/${salesOrderId}/workorderitems`,
                method: 'GET',
            }),
            transformResponse: result => SalesOrderWorkItemsResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.SalesOrderWorkItems, id }],
        }),

        /** Get work items and steps by sales order item id */
        salesItemWorkItems: build.query<SalesOrderWorkItem[], number>({
            query: itemId => ({
                url: `/salesorderitems/${itemId}/workorderitems`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => z.array(SalesOrderWorkItemSchema).parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.SalesItemWorkItemSteps, id }],
        }),

        salesOrderSplit: build.mutation<
            SalesOrderSplitResult,
            { orderId: number; orderItems: number[] }
        >({
            query: args => ({
                url: `/old/manufacturer-orders/${args.orderId}/split-single`,
                method: 'POST',
                data: {
                    lineIds: args.orderItems,
                },
            }),
            transformResponse: result => SalesOrderSplitResultSchema.parse(result),
            invalidatesTags: (req, res, args) => [
                ApiTagType.SalesOrderList,
                { type: ApiTagType.SalesOrderHistory, id: args.orderId },
                ApiTagType.SalesItemWorkItemSteps,
                { type: ApiTagType.SalesOrderWorkItems, id: args.orderId },
            ],
        }),
    }),
});

export default salesApi;
