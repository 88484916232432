import BarcodeScannerPng from 'assets/barcode-scanner.png';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import PageHeader from 'components/PageHeader/PageHeader';
import { WorkStationStepSummary } from 'features/workstations/models/WorkstationStepSummary';
import workstationsApi from 'features/workstations/workstations.api';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WorkstationStepsTable from '../WorkstationStepsTable/WorkstationStepsTable';
import './WorkstationGoToItemModal.scss';

export default function WorkstationGoToItemModal({
    stationId,
    workItemTuid,
    closeOnSelect = false,
    close,
}: {
    stationId: string;
    closeOnSelect?: boolean;
    close?: () => void;
    /* provide a workItemTuid to automatically search when modal opens */
    workItemTuid?: string;
}) {
    const [barcodeValue, setBarcodeValue] = useState('');
    const [isItemNotFound, setIsItemNotFound] = useState(false);
    const [executeQuery, queryState] = workstationsApi.useLazyWorkstationStepsQueryQuery();
    const navigate = useNavigate();

    const submitBarcode = useCallback(
        async (barcode: string) => {
            setBarcodeValue(barcode);
            setIsItemNotFound(false);
            const data = await executeQuery({
                stationId,
                barcode,
            }).unwrap();

            if (data.length === 0) {
                setIsItemNotFound(true);
                return false;
            }

            // open details modal immediately when there is only 1 step
            // but dont do this if a workItemTuid was provided
            if (data.length === 1 && !workItemTuid) {
                navigate(data[0].id);

                close?.();
                return true;
            }

            return true;
        },
        [close, executeQuery, navigate, stationId, workItemTuid],
    );

    const refreshResults = async () => {
        await executeQuery({
            stationId,
            barcode: barcodeValue,
        }).unwrap();
    };

    // only display results when more that one item was returned from the query
    const results = queryState.data?.length ? queryState.data : workItemTuid ? [] : undefined;

    useEffect(() => {
        if (workItemTuid) {
            submitBarcode(workItemTuid);
        }
    }, [submitBarcode, workItemTuid]);
    return (
        <WorkstationGoToItemModalPure
            isSubmitting={queryState.isFetching}
            submitBarcode={submitBarcode}
            refreshResults={refreshResults}
            isItemNotFound={isItemNotFound}
            barcode={barcodeValue}
            results={results}
            close={close}
            closeOnSelect={closeOnSelect}
        />
    );
}

export function WorkstationGoToItemModalPure({
    isSubmitting = false,
    isItemNotFound = false,
    submitBarcode,
    refreshResults,
    barcode,
    results,
    close,
    closeOnSelect,
}: {
    isSubmitting?: boolean;
    isItemNotFound?: boolean;
    submitBarcode?: (barcode: string) => Promise<boolean>;
    refreshResults?: () => Promise<void>;
    barcode: string;
    results?: WorkStationStepSummary[];
    close?: () => void;
    closeOnSelect?: boolean;
}) {
    if (!results) {
        return (
            <WorkstationGoToItemModal__Barcode
                isSubmitting={isSubmitting}
                submitBarcode={submitBarcode}
                isItemNotFound={isItemNotFound}
                close={close}
            />
        );
    }
    return (
        <WorkstationGoToItemModal__Results
            barcode={barcode}
            results={results}
            refreshResults={refreshResults}
            isRefreshing={isSubmitting}
            close={close}
            closeOnSelect={closeOnSelect}
        />
    );
}

export function WorkstationGoToItemModal__Barcode({
    isSubmitting = false,
    isItemNotFound = false,
    submitBarcode,
    close,
}: {
    isSubmitting?: boolean;
    isItemNotFound?: boolean;
    submitBarcode?: (barcode: string) => Promise<boolean>;
    close?: () => void;
}) {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    const runQuery = async () => {
        if (submitBarcode) {
            const success = await submitBarcode(inputValue);
            if (!success) {
                // select input text so you can scan again without pressing del/backspace first
                inputRef.current?.select();
            }
        }
    };

    useEffect(() => {
        if (!isSubmitting && isItemNotFound) {
            // select input text so you can scan again without pressing del/backspace first
            inputRef.current?.select();
        }
    }, [isSubmitting, isItemNotFound]);

    return (
        <MyModal
            className="WorkstationGoToItemModal__Barcode"
            close={close}
        >
            <h3 className="WorkstationGoToItemModal__Barcode__Title">Go to Item</h3>
            <p className="WorkstationGoToItemModal__Barcode__Prompt">
                Scan a work item barcode now
            </p>

            <img
                className="WorkstationGoToItemModal__Barcode__Image"
                src={BarcodeScannerPng}
                width={123}
            />

            <FormValidation submit={runQuery}>
                {({ handleSubmit }) => (
                    <form
                        className="WorkstationGoToItemModal__Barcode__Form"
                        onSubmit={handleSubmit}
                        key={1}
                    >
                        {isItemNotFound && (
                            <MessagePanel
                                className="WorkstationGoToItemModal__Barcode__NotFound"
                                messageType="error"
                                shake={true}
                            >
                                Work item not found, please try again
                            </MessagePanel>
                        )}
                        <FieldValidator
                            value={inputValue}
                            validationRequired="Please enter a value or scan a barcode"
                        >
                            <MyTextInput
                                className="WorkstationGoToItemModal__Barcode__BarcodeInput"
                                value={inputValue}
                                handleInput={setInputValue}
                                autoFocus={true}
                                disabled={isSubmitting}
                                onBlur={e => e.target.focus()}
                                inputRef={inputRef}
                            />
                            <MyButton
                                label="Go"
                                size="large"
                                type="submit"
                                isLoading={isSubmitting}
                                fullWidth
                            />
                        </FieldValidator>
                    </form>
                )}
            </FormValidation>
        </MyModal>
    );
}

export function WorkstationGoToItemModal__Results({
    barcode,
    results,
    refreshResults,
    isRefreshing = false,
    close,
    closeOnSelect,
}: {
    barcode: string;
    results?: WorkStationStepSummary[];
    refreshResults?: () => Promise<void>;
    isRefreshing?: boolean;
    close?: () => void;
    closeOnSelect?: boolean;
}) {
    return (
        <MyModal
            className="WorkstationGoToItemModal__Results"
            close={close}
            header={
                <PageHeader
                    title="Work Item Steps"
                    titleContext={barcode}
                    subtitle={results?.[0]?.context.workOrderItem.description}
                />
                //     <h1 className="WorkstationGoToItemModal__Results__Title">
                //         Steps for Work Order Item
                //     </h1>
                //     <p className="WorkstationGoToItemModal__Results__Tuid">{barcode}</p>
                //     <p className="WorkstationGoToItemModal__Results__Description">
                //         {results?.[0]?.context.workOrderItem.description}
                //     </p>
                // </>
            }
        >
            <WorkstationStepsTable
                data={results}
                showColTuid={false}
                showColSummaryFields={false}
                showColIdentifier={false}
                showColStatus={true}
                showColFilter={false}
                onRefresh={refreshResults}
                isRefreshing={isRefreshing}
                isLoading={isRefreshing && !results?.length}
                onRowClick={closeOnSelect ? close : undefined}
            />
        </MyModal>
    );
}
