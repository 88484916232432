import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import inventoryApi from 'features/inventory/inventory.api';
import { StocktakeTypeDisplay } from 'features/stocktake/enums/StocktakeType';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import workstationsApi from 'features/stocktake/stocktake.api';
import React from 'react';
import './StocktakeEditModal.scss';

export default function StocktakeEditModal({
    model,
    close,
}: {
    model: Stocktake;
    close?: () => void;
}) {
    const locationsQuery = inventoryApi.useLocationListQuery();
    const [updateMutation, updateMutationState] = workstationsApi.useStocktakeUpdateMutation();

    const save = async (editModel: Stocktake) => {
        await updateMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="StocktakeEditModal"
            isLoading={locationsQuery.isLoading}
            model={model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={updateMutationState.isLoading}
            title="Edit stocktake"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <div className="StocktakeEditModal__TopFields">
                        <PropertyDisplay
                            label="Warehouse"
                            value={editModel.parentLocationName}
                        />

                        <PropertyDisplay
                            label="Type"
                            value={StocktakeTypeDisplay.display(editModel.stocktakeType)}
                        />
                    </div>
                    <PropertyEditText
                        label="Notes"
                        multiline={true}
                        value={editModel.notes}
                        onChange={val => updateField({ notes: val })}
                        disabled={isSaving}
                    />
                </>
            )}
        </MyEditModal>
    );
}
