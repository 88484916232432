import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import SaveStatusIndicator from 'components/SaveStatusIndicator/SaveStatusIndicator';
import Env from 'config/Env';
import StocktakeStatus from 'features/stocktake/enums/StocktakeStatus';
import { CountSheet } from 'features/stocktake/models/CountSheet';
import { CountSheetInventory } from 'features/stocktake/models/CountSheetInventory';
import { CountSheetSummary } from 'features/stocktake/models/CountSheetSummary';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import workstationsApi from 'features/stocktake/stocktake.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { selectEditModel, setEditModel, updateEditModelField } from 'store/forms.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { generateShortUuid } from 'utils/helpers';
import {
    StocktakeQuantityInput,
    StocktakeQuantityUpdateData,
} from '../StocktakeQuantityInput/StocktakeQuantityInput';
import './StocktakeCountSheetModal.scss';

const EDIT_MODEL_ID = 'StocktakeCountSheet';

export default function StocktakeCountSheetModal({
    model,
    summary,
    stocktake,
    isLoading,
    isError,
    close,
    onRefresh,
    isRefreshing = false,
}: {
    model?: CountSheet;
    summary?: CountSheetSummary;
    stocktake: Stocktake;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
    onRefresh?: () => void;
    isRefreshing?: boolean;
}) {
    const editModel = useAppSelector(selectEditModel(EDIT_MODEL_ID)) as CountSheet | undefined;
    const dispatch = useAppDispatch();
    const dialogManager = useDialogManager();

    const isComplete = stocktake.status === StocktakeStatus.Complete;

    useEffect(() => {
        const thisModel = model;
        if (thisModel) {
            dispatch(setEditModel({ id: EDIT_MODEL_ID, model }));
        }
        return () => {
            if (thisModel) {
                dispatch(setEditModel({ id: EDIT_MODEL_ID, model: undefined }));
            }
        };
    }, [dispatch, model]);

    const [updateMutation] = workstationsApi.useCountSheetUpdateMutation();

    /** Keep track of rows that are currently being saved */
    const pendingSavesRef = useRef<{ [key: string]: PendingSave }>({});

    const setSaveState = useCallback((id: string, statusId: string, status?: PendingSaveStatus) => {
        const latestSave = pendingSavesRef.current[id];
        if (!latestSave || latestSave.id === statusId) {
            if (status) {
                pendingSavesRef.current[id] = {
                    id: statusId,
                    status,
                };
            } else {
                delete pendingSavesRef.current[id];
            }
        }
    }, []);

    const handleClose = () => {
        const pendingSaves = Object.keys(pendingSavesRef.current).filter(
            k => pendingSavesRef.current[k].status === 'pending',
        );
        if (pendingSaves.length === 0) {
            close?.();
        } else {
            dialogManager.toast({
                message: 'Please wait for pending saves to complete',
            });
        }
    };

    const onQuantityChanged = useCallback(
        async (data: StocktakeQuantityUpdateData) => {
            if (model && editModel) {
                // update in redux first
                const inv = [...editModel.inventory];
                const index = inv.findIndex(i => i.tenantInventoryId === data.tenantInventoryId);
                if (index > -1) {
                    const item = inv[index];

                    // only update if qty haas changed
                    if (item.countedQuantity !== data.countedQuantity) {
                        inv.splice(index, 1, {
                            ...item,
                            countedQuantity: data.countedQuantity,
                        });

                        dispatch(
                            updateEditModelField({
                                id: EDIT_MODEL_ID,
                                data: {
                                    inventory: inv,
                                },
                            }),
                        );

                        // Save to BE
                        const pendingSaveId = generateShortUuid();
                        try {
                            // track this id in pendingSaveStates
                            // replace any previous pendingSaveRef first
                            delete pendingSavesRef.current[data.tenantInventoryId];
                            setSaveState(data.tenantInventoryId, pendingSaveId, 'pending');

                            // now post an update
                            const result = await updateMutation({
                                id: editModel.id,
                                key: editModel.key,
                                stocktakeId: stocktake.id,
                                tenantInventoryId: data.tenantInventoryId,
                                countedQuantity: data.countedQuantity,
                            });
                            if ((result as any).error) {
                                // update status to 'fail'
                                setSaveState(data.tenantInventoryId, pendingSaveId, 'fail');

                                // reload previous data
                                onRefresh?.();
                            } else {
                                // update status to 'success'
                                setSaveState(data.tenantInventoryId, pendingSaveId, 'success');
                            }
                        } catch (e) {
                            setSaveState(data.tenantInventoryId, pendingSaveId, 'fail');
                            onRefresh?.();
                        }
                    }
                }
            }
        },
        [dispatch, editModel, model, onRefresh, setSaveState, stocktake.id, updateMutation],
    );

    const dataTableColumns = useMemo(() => {
        if (!editModel) {
            return [];
        }
        return ColumnBuilder<CountSheetInventory>()
            .column({
                label: 'Part No',
                key: 'partNumber',
                isSortable: false,
                getValue: item => item.partNumber,
            })
            .column({
                label: 'Description',
                key: 'description',
                isSortable: false,
                getValue: item => item.description,
            })
            .column({
                label: 'Expected',
                key: 'expectedQuantity',
                isSortable: false,
                getValue: item => item.expectedQuantity,
            })
            .column({
                label: 'Quantity',
                key: 'countedQuantity',
                isSortable: false,
                getValue: item => item.countedQuantity,
                renderValue: (qty, item) =>
                    isComplete ? (
                        qty
                    ) : (
                        <StocktakeQuantityInput
                            item={item}
                            allowBlank={true}
                            onChange={onQuantityChanged}
                        />
                    ),
            })
            .column({
                label: '',
                key: 'status',
                render: item => {
                    const status = pendingSavesRef.current[item.tenantInventoryId]?.status;
                    return (
                        <SaveStatusIndicator
                            isSaving={status === 'pending'}
                            isError={status === 'fail'}
                            isSuccess={status === 'success'}
                        />
                    );
                },
            })
            .build();
    }, [editModel, onQuantityChanged, isComplete]);

    return (
        <MyModal
            className="StocktakeCountSheetModal"
            isLoading={isLoading}
            isError={isError}
            close={handleClose}
        >
            <PageHeader
                className="StocktakeCountSheetModal__PageHeader"
                title="Count Sheet"
                titleContext={editModel?.key}
                subtitle={
                    <>
                        Stocktake {stocktake.number}
                        <br />
                        {stocktake.parentLocationName}, {summary?.locationName}
                    </>
                }
            >
                {!isComplete && (
                    <MyButton
                        label="Get PDF"
                        IconLeft={Icons.Download}
                        buttonType="Hollow"
                        href={`${Env.API_BASE_URL}/stocktake/${stocktake.id}/countsheet/${model?.key}/pdf`}
                        LinkComponent={MyButtonLinkNewTab}
                    />
                )}
            </PageHeader>
            {editModel && (
                <DataTable
                    className="StocktakeCountSheetModal__InventoryTable"
                    data={editModel.inventory}
                    columns={dataTableColumns}
                    onRefresh={onRefresh}
                    isRefreshing={isRefreshing}
                />
            )}
        </MyModal>
    );
}

type PendingSaveStatus = 'pending' | 'success' | 'fail';
type PendingSave = {
    id: string;
    status: PendingSaveStatus;
};
