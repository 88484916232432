import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import { useBrandOptions } from 'features/customers/hooks/useBrandOptions';
import DocumentFileIcon from 'features/documents/components/DocumentFileIcon/DocumentFileIcon';
import documentsApi from 'features/documents/documents.api';
import { getDocumentFriendlyType } from 'features/documents/enums/DocumentFriendlyType';
import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import './DocumentUploadModal.scss';

export type DocumentUploadFileArgs = {
    file: File;
    brandId: number | null;
};

export default function DocumentUploadModal({
    showBrand,
    directUpload,
    afterUpload,
    close,
}: {
    showBrand?: boolean;
    directUpload: boolean;
    afterUpload?: (doc: DocumentUploadResultDocument) => Promise<any | void>;
    close?: () => void;
}) {
    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadDocumentMutation] = documentsApi.useDocumentUploadMutation();
    const dialogManager = useDialogManager();

    const dz = useDropzone({
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'document/pdf': ['.pdf'],
            'document/word': ['.doc', '.docx'],
            'document/excel': ['.xls', '.xlsx'],
            'document/csv': ['.csv'],
            'document/text': ['.txt', '.msg'],
        },
    });

    useEffect(
        () => {
            if (dz.acceptedFiles.length > 0) {
                setFiles([...files, ...dz.acceptedFiles.filter(f => !files.includes(f))]);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dz.acceptedFiles],
    );

    // Use query param from DocumentsTable to set brand default value
    const [brandDefaultStr] = useUrlQueryState('brand');
    const brandDefault = useMemo(() => {
        if (showBrand) {
            const num = brandDefaultStr ? parseInt(brandDefaultStr, 10) : null;
            return Number.isNaN(num) ? null : num;
        }
        return null;
    }, [brandDefaultStr, showBrand]);

    const [brandId, setBrandId] = useState<number | null>(brandDefault);
    const brandOptions = useBrandOptions();

    const handleRemoveFile = useCallback(
        (file: File) => {
            setFiles(files.filter(f => f !== file));
        },
        [files],
    );

    /** Upload an individual file and call afterUpload if defined  */
    const uploadFile = useCallback(
        async (file: File) => {
            const result = await uploadDocumentMutation({
                file,
                brandId,
                directUpload,
            }).unwrap();

            if (afterUpload) {
                await afterUpload(result.data.document);
            }
        },
        [afterUpload, brandId, directUpload, uploadDocumentMutation],
    );

    /** Begin upload of all selected files */
    const handleUpload = useCallback(async () => {
        setIsUploading(true);
        try {
            const promises = files.map(f => uploadFile(f));
            await Promise.all(promises);

            // close after all files uploaded
            close?.();
        } catch (e) {
            setIsUploading(false);
            throw e;
        }
    }, [close, files, uploadFile]);

    const handleClose = useCallback(() => {
        if (isUploading) {
            dialogManager.alert({
                title: 'Upload is still in progress',
                message: 'Please wait for all uploads to finish before closing',
            });
        } else {
            close?.();
        }
    }, [close, dialogManager, isUploading]);

    return (
        <MyModal
            className="DocumentUploadModal"
            close={handleClose}
            header={<PageHeader title="Upload documents" />}
        >
            <div {...dz.getRootProps({ className: 'DocumentUploadModal__Dropzone' })}>
                <p>
                    <b>Drag some files here, or click to open file picker</b>
                </p>
                <p>
                    Accepted file extensions are{' '}
                    <em>jpg, jpeg, png, pdf, doc, docx, csv, txt, xls, xlsx, msg</em>.
                </p>
                <p>Max file size is 25MB.</p>
                <input {...dz.getInputProps()} />
            </div>

            {files.length > 0 && (
                <ul className="DocumentUploadModal__FileList">
                    {files.map((file, index) => (
                        <FileItem
                            key={index}
                            file={file}
                            disabled={isUploading}
                            onRemove={handleRemoveFile}
                        />
                    ))}
                </ul>
            )}

            {showBrand && (
                <PropertyContainer fullWidthInputs>
                    <PropertyEditAutocomplete
                        label="Brand (optional)"
                        hint="This can't be changed after upload"
                        value={`${brandId}`}
                        onChange={(val: string) =>
                            setBrandId(isEmpty(val) ? null : parseInt(val, 10))
                        }
                        options={brandOptions}
                        disabled={isUploading}
                    />
                </PropertyContainer>
            )}

            <div className="DocumentUploadModal__Footer">
                <MyButton
                    label="Cancel"
                    buttonType="Hollow"
                    onClick={handleClose}
                    disabled={isUploading}
                />
                <MyButton
                    label="Upload now"
                    buttonType="Primary"
                    IconLeft={Icons.Upload}
                    onClick={handleUpload}
                    isLoading={isUploading}
                    disabled={!files.length}
                />
            </div>
        </MyModal>
    );
}

function FileItem({
    file,
    disabled,
    onRemove,
}: {
    file: File;
    disabled: boolean;
    onRemove?: (file: File) => void;
}) {
    const sizeKb = Math.round(file.size / 1024);
    const type = getDocumentFriendlyType(file.name);
    return (
        <li
            className={coalesceClassNames(
                'DocumentUploadModal__FileItem',
                disabled && 'DocumentUploadModal__FileItem--disabled',
            )}
        >
            <DocumentFileIcon type={type} />
            <div className="DocumentUploadModal__FileItem__Name">{file.name}</div>
            <div>{sizeKb === 0 ? <>{file.size} bytes</> : <>{sizeKb} kb</>}</div>
            <MyButton
                className="DocumentUploadModal__FileItem__RemoveButton"
                IconLeft={Icons.Close}
                onClick={() => onRemove?.(file)}
                title="Remove"
                buttonType="None"
                size="small"
                disabled={disabled}
            />
        </li>
    );
}
