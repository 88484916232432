import { faker } from '@faker-js/faker';
import { fakeSalesOrders } from 'features/sales/faker/SalesFaker';
import { generateFakeSchedule } from 'features/schedule/faker/ScheduleFaker';
import { DateTime } from 'luxon';
import WorkItemStatus from '../enums/WorkItemStatus';
import { WorkOrderDetail } from '../models/WorkOrderDetail';
import { WorkOrderItemDetail } from '../models/WorkOrderItemDetail';

faker.seed(42);
export function generateFakeWorkOrderDetail(): WorkOrderDetail {
    return {
        id: faker.string.uuid(),
        tuid: `WRK${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        notes: '',

        context: {
            workOrderItems: [],
            schedule: {
                id: 'dc0d21e2-00a6-4373-a3aa-809e6cc3d4a6',
                date: '2023-10-25',
            },
            scheduleMeta: {
                sortOrder: 5,
            },
            workstationGroupAssignment: undefined,
        },
    };
}

export function generateFakeWorkItem(workOrder: WorkOrderDetail): WorkOrderItemDetail {
    const salesOrder = fakeSalesOrders.find(
        s => `${s.id}` === workOrder.context.workOrderItems[0]?.salesOrderId,
    );
    const schedule = generateFakeSchedule(DateTime.now().startOf('day'));

    return {
        id: faker.string.uuid(),
        tuid: `WOI${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        description: faker.commerce.productName(),
        customerOrderReference: '',
        customerOrderSidemark: '',
        salesOrderId: `${salesOrder?.id ?? ''}`,
        salesOrderLineId:
            (salesOrder?.context.orderLines.length ?? 0) > 0
                ? `${faker.helpers.arrayElement(salesOrder?.context.orderLines ?? []).id}`
                : '',
        salesOrderReference: salesOrder?.manufacturerReference ?? '',
        isCancelled: false,
        workflowId: faker.string.uuid(),
        workOrderId: workOrder.id,
        labels: {},
        options: {},
        context: {
            workOrder,
            workOrderItemState: faker.helpers.enumValue(WorkItemStatus),
            schedule,
            scheduleMeta: {
                sortOrder: 0,
            },
            workOrderItemSteps: [],
        },
    };
}

export const fakeWorkOrders = faker.helpers.multiple(generateFakeWorkOrderDetail, { count: 20 });

export const fakeWorkItems = fakeWorkOrders.reduce((arr, wo) => {
    return [
        ...arr,
        ...faker.helpers.multiple(() => generateFakeWorkItem(wo), { count: { min: 1, max: 5 } }),
    ];
}, [] as WorkOrderItemDetail[]);
