import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import MyTabs from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import { selectNewVersionAvailable } from 'features/auth/auth.slice';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import WorkstationListName from 'features/workstations/enums/WorkstationListName';
import useStepFilters from 'features/workstations/hooks/useStepFilters';
import useStepsListNameParam from 'features/workstations/hooks/useStepsListNameParam';
import useStepsTableData from 'features/workstations/hooks/useStepsTableData';
import { WorkStationStepSummary } from 'features/workstations/models/WorkstationStepSummary';
import { selectCurrentWorkstation } from 'features/workstations/workstations.slice';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { dateIsPast, dateIsToday, formatDateRelative } from 'utils/dateHelpers';
import { isEmpty } from 'utils/helpers';
import { usePageTitle } from 'utils/locationHelpers';
import WorkstationGoToItemModal from '../WorkstationGoToItemModal/WorkstationGoToItemModal';
import WorkstationSelector from '../WorkstationSelector/WorkstationSelector';
import WorkstationStepsTable, {
    WorkstationStepsTableStep,
} from '../WorkstationStepsTable/WorkstationStepsTable';
import './WorkstationMain.scss';

export default function WorkstationMain() {
    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    usePageTitle(currentWorkstation?.name);
    const [tab, setTab] = useStepsListNameParam();

    const [showGoToItem, setShowGoToItem] = useState(false);
    const [showChangeWorkstationModal, setShowChangeWorkstationModal] = useState(false);

    const isUpdateAvailable = useAppSelector(selectNewVersionAvailable);
    useEffect(() => {
        if (isUpdateAvailable) {
            window.location.reload();
        }
    }, [isUpdateAvailable]);

    return (
        <div className="WorkstationMain">
            <PageHeader
                className="WorkstationMain__PageHeader"
                title="Workstation"
                titleContext={currentWorkstation?.name}
                subtitle={
                    <MyButton
                        label="change workstation"
                        buttonType="LinkButton"
                        onClick={() => setShowChangeWorkstationModal(true)}
                    />
                }
            >
                <MyButton
                    label="Go to item"
                    IconLeft={Icons.Barcode}
                    buttonType="Secondary"
                    onClick={() => setShowGoToItem(true)}
                />
            </PageHeader>

            <MyTabs
                className="SalesOrderDetailModal__Tabs"
                activeTab={tab}
                setActiveTab={setTab}
                tabs={[
                    {
                        name: WorkstationListName.Ready,
                        label: 'My Queue',
                        content: <MyQueueTab />,
                    },
                    {
                        name: WorkstationListName.ComingUp,
                        label: 'Coming Up',
                        content: <ComingUpTab />,
                    },
                    {
                        name: WorkstationListName.History,
                        label: 'History',
                        content: <HistoryTab />,
                    },
                ]}
            />

            {currentWorkstation && showGoToItem && (
                <WorkstationGoToItemModal
                    stationId={currentWorkstation.id}
                    close={() => setShowGoToItem(false)}
                />
            )}

            {showChangeWorkstationModal && (
                <MyModal
                    className="WorkstationMain__ChangeWorkstationModal"
                    close={() => setShowChangeWorkstationModal(false)}
                >
                    <PageHeader title="Change Workstation" />
                    <WorkstationSelector />
                </MyModal>
            )}
        </div>
    );
}

function MyQueueTab() {
    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const [stepsData, query] = useStepsTableData({
        workstationId: currentWorkstation?.id || '',
        listName: WorkstationListName.Ready,
    });

    // Up Next contains any rows that have been paused
    // Plus any InProgress steps
    // and the next Unstarted step if there is one
    const upNextData = stepsData.filter(step =>
        [WorkItemStepStatus.OnHold, WorkItemStepStatus.InProgress].includes(step.status),
    );

    const nextStep = stepsData.find(step => step.status === WorkItemStepStatus.Unstarted);
    if (nextStep) {
        upNextData?.push(nextStep);
    }

    // Refresh the data when switching tabs
    useEffect(() => {
        if (query?.isSuccess) {
            query?.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** stepsData grouped by date */
    const groupedData = useMemo(() => groupByDate(stepsData), [stepsData]);

    return (
        <>
            <TabHeader title="My Queue" />
            {groupedData.map(group => (
                <div
                    key={group.date}
                    className="WorkstationMain__Group"
                >
                    <h2 className="WorkstationMain__Group__DateHeader">
                        <Icons.Schedule className="icon" />
                        {formatDateRelative(group.date, { alwaysDate: true })}
                        {dateIsToday(group.date) ? (
                            <span className="today-badge">Today</span>
                        ) : (
                            dateIsPast(group.date) && <span className="late-badge">Late!</span>
                        )}
                    </h2>
                    <WorkstationStepsTable
                        data={group.steps}
                        isLoading={query?.isLoading}
                        isError={query?.isError}
                        showColStatus={true}
                        onRefresh={query?.refetch}
                        isRefreshing={query?.isFetching}
                    />
                </div>
            ))}
        </>
    );
}

function ComingUpTab() {
    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const [stepsData, query] = useStepsTableData({
        workstationId: currentWorkstation?.id || '',
        listName: WorkstationListName.ComingUp,
    });

    // Refresh the data when switching tabs
    useEffect(() => {
        if (query?.isSuccess) {
            query?.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** stepsData grouped by date */
    const groupedData = useMemo(() => groupByDate(stepsData), [stepsData]);

    return (
        <>
            <TabHeader title="Coming Up" />
            {groupedData.map(group => (
                <div
                    key={group.date}
                    className="WorkstationMain__Group"
                >
                    <h2 className="WorkstationMain__Group__DateHeader">
                        <Icons.Schedule className="icon" />
                        {formatDateRelative(group.date)}
                    </h2>
                    <WorkstationStepsTable
                        data={group.steps}
                        isLoading={query?.isLoading}
                        isError={query?.isError}
                        showColStatus={true}
                        onRefresh={query?.refetch}
                        isRefreshing={query?.isFetching}
                    />
                </div>
            ))}
        </>
    );
}

function HistoryTab() {
    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const [stepsData, query] = useStepsTableData({
        workstationId: currentWorkstation?.id || '',
        listName: WorkstationListName.History,
    });

    // Refresh the data when switching tabs
    useEffect(() => {
        if (query?.isSuccess) {
            query?.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TabHeader title="History" />
            <WorkstationStepsTable
                data={stepsData}
                isLoading={query?.isLoading}
                isError={query?.isError}
                showColStatus={true}
                onRefresh={query?.refetch}
                isRefreshing={query?.isFetching}
            />
        </>
    );
}

function TabHeader({ title }: { title: string }) {
    const { activeFilters, removeFilter } = useStepFilters();

    return (
        <div className="WorkstationMain__TabHeader">
            <h2 className="WorkstationMain__TabHeader__Title">{title}</h2>
            {activeFilters.length > 0 && (
                <div className="WorkstationMain__TabHeader__ActiveFilters">
                    {activeFilters?.map(f => (
                        <MyButton
                            key={f.key}
                            className="WorkstationMain__TabHeader__ActiveFilters__FilterButton"
                            buttonType="Primary"
                            onClick={() => removeFilter(f)}
                        >
                            <div className="label">{f.displayName}</div>
                            <div className="value">{isEmpty(f.value) ? '-' : f.value}</div>
                            <div className="remove">
                                <Icons.Close />
                            </div>
                        </MyButton>
                    ))}
                </div>
            )}
        </div>
    );
}

type GroupStruct = {
    date: string;
    steps: WorkstationStepsTableStep[];
};

function groupByDate(data: WorkStationStepSummary[]): GroupStruct[] {
    const groups = data.reduce((arr, step) => {
        const date = step.context.schedule.date;
        let group = arr.find(g => g.date === date);
        if (!group) {
            // if no group yet, create a new group
            group = {
                date,
                steps: [],
            };
            arr.push(group);
        }
        group.steps.push(step);
        return arr;
    }, [] as GroupStruct[]);

    if (!groups.some(g => dateIsToday(g.date))) {
        // Add a group for today at the end of the list
        const currentDate = {
            date: DateTime.now().toISODate(),
            steps: [],
        } as GroupStruct;

        groups.push(currentDate);
    }

    return groups;
}
