import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import { SalesOrderStatusSchema } from './SalesOrderStatus';

export const SalesOrderSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    accountId: z.number(),
    createdAt: dateTimeInstant(),
    customerNotes: z.string().nullable(),
    eta: z.string().nullable(),
    legacyId: z.number(),
    manufacturerReference: z.string(),
    notes: z.string().nullable(),
    orderStatusId: z.number(),
    totalCostPrice: z.number(),
    totalFreight: z.number(),
    totalFreightOverride: z.number().nullable(),
    totalSellPrice: z.number(),
    totalTax: z.number(),
    trackingInformation: z.string().nullable(),

    context: z.object({
        customerOrderGroup: z.object({
            id: z.number(),
            customerDetailsOverride: z.string().nullable(),
            uniqueId: z.string(),
            orderNotes: z.string(),
        }),
        customerPurchaseOrder: z.object({
            id: z.number(),
            brandId: z.number(),
            categoryId: z.number().nullable(),
            splitId: z.number(),
            purchaseOrderId: z.string(),
            sidemark: z.string(),
        }),
        customerConfig: z.object({
            isInternal: z.boolean(),
        }),
        externalSiteContact: z
            .object({
                firstName: z.string(),
                lastName: z.string(),
            })
            .optional(),
        externalSiteBusiness: z
            .object({
                name: z.string(),
            })
            .optional(),
        itemCount: z.number(),
        mainCompany: z.object({
            name: z.string(),
        }),
        status: SalesOrderStatusSchema,
        isArchived: z.boolean(),
    }),
});

export type SalesOrder = z.infer<typeof SalesOrderSchema>;

/** Computed customer display name
 * For external customers this is always the main company name
 * For internal customers it is computed from customerDetailsOverride, business name or contact name
 */
export function getCustomerDisplayName(order: SalesOrder): string {
    if (!order.context.customerConfig.isInternal) {
        return order.context.mainCompany.name;
    }
    let customerName: string | null | undefined =
        order.context.customerOrderGroup.customerDetailsOverride;
    if (!customerName) {
        customerName = order.context.externalSiteBusiness?.name;
    }
    if (!customerName) {
        const contact = order.context.externalSiteContact;
        const name = `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`.trim();
        customerName = name || 'unknown';
    }
    return customerName;
}
