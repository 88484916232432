import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditDateTime from 'components/PropertyEditDateTime/PropertyEditDateTime';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import dispatchApi from 'features/dispatch/dispatch.api';
import { ShipmentEntity, ShipmentEntityFactory } from 'features/dispatch/models/ShipmentEntity';
import React, { useMemo } from 'react';
import './ShipmentCreateModal.scss';

export default function ShipmentCreateModal({ close }: { close?: () => void }) {
    const model = useMemo(() => ShipmentEntityFactory.create({}), []);
    const [saveMutation, saveMutationState] = dispatchApi.useShipmentCreateMutation();

    const save = async (editModel: ShipmentEntity) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="ShipmentCreateModal"
            model={model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="New shipment"
        >
            {({ editModel, updateField, isSaving }) => (
                <PropertyContainer>
                    <PropertyEditText
                        label="Name"
                        value={editModel.name}
                        onChange={name => updateField({ name })}
                        autoFocus={true}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                    <PropertyEditDateTime
                        label="Date"
                        value={editModel.date}
                        onChange={date => updateField({ date })}
                        disabled={isSaving}
                        defaultTime="09:00"
                        validationRequired={true}
                    />
                    <PropertyEditNumber
                        label="Shipment days"
                        hint="Estimated number of days in transit"
                        value={editModel.shipmentDays}
                        onChange={shipmentDays => updateField({ shipmentDays })}
                        min={0}
                        allowBlank={false}
                        disabled={isSaving}
                    />
                    <PropertyEditText
                        label="Notes"
                        value={editModel.notes}
                        onChange={notes => updateField({ notes })}
                        multiline={true}
                        disabled={isSaving}
                    />
                </PropertyContainer>
            )}
        </MyEditModal>
    );
}
