import { DateTime } from 'luxon';
import { dateOnly } from 'utils/dateSchemas';
import { mergeDeep } from 'utils/helpers';
import z from 'zod';
import InventoryReceiptStatus from '../enums/InventoryReceiptStatus';

export const InventoryReceiptSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    isArchived: z.boolean(),
    status: z.nativeEnum(InventoryReceiptStatus),
    locationId: z.string(), // Parent location only
    notes: z.string(),
    dateReceived: dateOnly({ allowBlank: false }), // default to today, editable after creation while in draft
    inventory: z
        .array(
            z.object({
                inventoryId: z.string(),
                quantity: z.number(),
                locations: z
                    .array(
                        z.object({
                            locationId: z.string(),
                            quantity: z.number(),
                            containers: z
                                .array(
                                    z.object({
                                        reference: z.string(),
                                        quantity: z.number(),
                                    }),
                                )
                                .default([]),
                        }),
                    )
                    .default([]),
            }),
        )
        .default([]),
});

export type InventoryReceipt = z.infer<typeof InventoryReceiptSchema>;

export class InventoryReceiptFactory {
    static create(data?: Partial<InventoryReceipt>) {
        const defaults: InventoryReceipt = {
            id: '',
            tuid: '',
            isArchived: false,
            locationId: '',
            dateReceived: DateTime.now().toFormat(`yyyy-MM-dd`) ?? '',
            notes: '',
            status: InventoryReceiptStatus.Draft,
            inventory: [],
        };

        const mergedData = mergeDeep(defaults, data);

        return InventoryReceiptSchema.parse(mergedData);
    }
}
