import z from 'zod';
import ContainerType from '../enums/ContainerType';
import InventoryType from '../enums/InventoryType';
import { InventoryTotalsSchema } from './InventoryTotals';

export const LocationInventorySchema = InventoryTotalsSchema.extend({
    context: z.object({
        inventory: z.object({
            id: z.string(),
            inventoryType: z.nativeEnum(InventoryType),
            containerType: z.nativeEnum(ContainerType),
            partNumber: z.string(),
            description: z.string(),
            notes: z.string(),
            minimumQuantityLevel: z.number(),
            maximumQuantityLevel: z.number(),
            reorderQuantity: z.number().nullable(),
        }),
    }),
});

export type LocationInventory = z.infer<typeof LocationInventorySchema>;
