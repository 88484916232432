import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum ContainerType {
    None = 'NONE',
    Roll = 'ROLL',
}
export default ContainerType;

const labels: EnumDisplayLabels<typeof ContainerType> = {
    None: 'None',
    Roll: 'Roll',
};

export const ContainerTypeDisplay = createDisplayEnum(ContainerType, labels);
