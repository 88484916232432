import DataTablePaging from 'components/DataTable/DataTablePaging';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { SalesItem } from 'features/sales/models/SalesItem';
import { SalesItemHistoryState } from 'features/sales/models/SalesItemHistoryEntry';
import salesApi, { SalesItemHistoryParams } from 'features/sales/sales.api';
import useDynamicQueryParams from 'hooks/useDynamicQueryParams';
import React, { useMemo } from 'react';
import { isEmpty } from 'utils/helpers';
import HistoryEntryDisplay from '../HistoryEntryDisplay/HistoryEntryDisplay';
import SalesItemStatusBadge from '../SalesItemStatusBadge/SalesItemStatusBadge';
import './SalesItemDrawerHistoryTab.scss';

// These are the attributes of a sales order line that we want to display on the history view
const historyFieldLabels: Record<string, string> = {
    notes: 'notes',
    order_line_status_id: 'Status',
    quantity: 'Quantity',
    unit_cost_price: 'Unit cost price',
    unit_sell_price: 'Unit sell price',
    unit_tax: 'Unit tax',
};

export function SalesItemDrawerHistoryTab({ model }: { model: SalesItem }) {
    const [queryParams, , , paging, setPaging] = useDynamicQueryParams<SalesItemHistoryParams>(
        {
            id: model.id,
        },
        {
            pageSize: 20,
        },
    );
    const query = salesApi.useSalesItemHistoryQuery(queryParams);

    /** Map SalesItemHistoryEntry to HistoryEntryDisplay params */
    const historyEntries = useMemo(() => {
        return query.data?.data.map(entry => {
            const entries = Object.entries(entry.new).filter(([k, value]) => {
                const key = k as keyof SalesItemHistoryState;
                const oldVal = entry.old[key];
                return oldVal !== value && !!historyFieldLabels[key];
            });

            const log = entry.type === 'order-line-dl' ? entry.logs : null;

            // dont bother computing changedFields if we're displaying log
            const changedFields = log
                ? []
                : entries.map(([key, value]) => ({
                      label: historyFieldLabels[key] ?? '',
                      displayValue:
                          key === 'order_line_status_id' ? (
                              <SalesItemStatusBadge
                                  statusId={value as number}
                                  size="small"
                              />
                          ) : (
                              value
                          ),
                  }));

            return {
                id: entry.id,
                log,
                changedFields,
                updatedBy: entry.manufacturer_employees_full_name,
                timestamp: entry.created_at,
            };
        });
    }, [query.data]);

    return (
        <>
            {query.isLoading ? (
                <div className="SalesItemDrawerHistoryTab__Loading">
                    <MyLinearProgress />
                </div>
            ) : query.isError ? (
                <ErrorContent />
            ) : isEmpty(historyEntries) ? (
                <div className="SalesItemDrawerHistoryTab__SectionEmpty">
                    No history entries found
                </div>
            ) : (
                historyEntries?.map(entry => (
                    <HistoryEntryDisplay
                        key={entry.id}
                        changedFields={entry.changedFields}
                        log={entry.log}
                        updatedBy={entry.updatedBy}
                        timestamp={entry.timestamp}
                    />
                ))
            )}

            {(query.data?.data.length ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setPaging}
                />
            )}
        </>
    );
}
