import Icons from 'Icons';
import MyTextInput, { MyTextInputProps } from 'components/MyTextInput/MyTextInput';
import React, { useCallback, useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import './MyMoneyInput.scss';

export type MyMoneyInputProps = Omit<MyTextInputProps, 'value' | 'handleInput'> & {
    value?: number;
    handleInput?: (val?: number) => void;
    allowBlank?: boolean;
};

export default function MyMoneyInput({
    className,
    value,
    handleInput,
    disabled = false,
    min,
    max,
    allowBlank,
    ...props
}: MyMoneyInputProps) {
    const [strVal, setStrVal] = useState<string>(formatValue(value));

    useEffect(() => {
        setStrVal(isEmpty(value) ? '' : `${value}`);
    }, [value]);

    const onInputChanged = useCallback(
        (val: string) => {
            setStrVal(val);

            if (isEmpty(val)) {
                if (allowBlank) {
                    handleInput?.(undefined);
                }
            }
            const num = parseFloat(val);
            if (!Number.isNaN(num)) {
                handleInput?.(num);
            }
        },
        [allowBlank, handleInput],
    );

    // Reset the input string value on blur
    const handleBlur = useCallback(() => {
        setStrVal(formatValue(value));
    }, [value]);

    return (
        <MyTextInput
            className={coalesceClassNames('MyMoneyInput', className)}
            value={strVal}
            type="number"
            min={min}
            max={max}
            disabled={disabled}
            handleInput={onInputChanged}
            onBlur={handleBlur}
            LeftIcon={Icons.Dollar}
            {...props}
        />
    );
}

function formatValue(val?: number) {
    const str = val?.toFixed(2) ?? '';
    return str.replace('.00', '');
}
