import ErrorContent from 'components/ErrorContent/ErrorContent';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyButton from 'components/MyButton/MyButton';
import MyCheckboxTree from 'components/MyCheckboxTree/MyCheckboxTree';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import LocationType from 'features/inventory/enums/LocationType';
import inventoryApi from 'features/inventory/inventory.api';
import { selectPrimaryLocationId, setPrimaryLocationId } from 'features/inventory/inventory.slice';
import StocktakeTargetType from 'features/stocktake/enums/StocktakeTargetType';
import StocktakeType, { StocktakeTypeDisplay } from 'features/stocktake/enums/StocktakeType';
import useGroupNodes from 'features/stocktake/hooks/useGroupNodes';
import useLocationNodes from 'features/stocktake/hooks/useLocationNodes';
import { StocktakeCreate, StocktakeCreateFactory } from 'features/stocktake/models/StocktakeCreate';
import workstationsApi from 'features/stocktake/stocktake.api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import typescriptNaturalSort from 'typescript-natural-sort';
import { parseEnum } from 'utils/helpers';
import './StocktakeCreateModal.scss';

export default function StocktakeCreateModal({ close }: { close?: () => void }) {
    const locationsQuery = inventoryApi.useLocationListQuery();
    const [saveMutation, saveMutationState] = workstationsApi.useStocktakeCreateMutation();

    const [model, setModel] = useState<StocktakeCreate>();

    const primaryLocationId = useAppSelector(selectPrimaryLocationId);

    const save = async (editModel: StocktakeCreate) => {
        const data = {
            ...editModel,
            // dont post selected targets for StocktakeType.Complete
            targets: editModel.stocktakeType === StocktakeType.Complete ? [] : editModel.targets,
        };
        await saveMutation(data).unwrap();
        close?.();
    };

    useEffect(() => {
        // init model when query has loaded
        if (locationsQuery.data && !model) {
            setModel(
                StocktakeCreateFactory.create({
                    parentLocationId: primaryLocationId,
                }),
            );
        }
    }, [locationsQuery.data, model, primaryLocationId]);

    return (
        <MyEditModal
            className="StocktakeCreateModal"
            isLoading={locationsQuery.isLoading}
            model={model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="New stocktake"
        >
            {({ editModel, isEditing, updateField, isSaving }) => (
                <FormFields
                    editModel={editModel}
                    isEditing={isEditing}
                    updateField={updateField}
                    isSaving={isSaving}
                />
            )}
        </MyEditModal>
    );
}

function FormFields({
    editModel,
    isEditing,
    updateField,
    isSaving,
}: {
    editModel: StocktakeCreate;
    isEditing: boolean;
    updateField: (data: Partial<StocktakeCreate>) => void;
    isSaving: boolean;
}) {
    const locationsQuery = inventoryApi.useLocationListQuery();
    const dispatch = useDispatch();

    const [countBy, setCountBy] = useState<'location' | 'group'>('location');

    const parentLocationOptions = useMemo(() => {
        const parentOpts = (locationsQuery.data?.data ?? [])
            .filter(l => l.locationType === LocationType.Warehouse && !l.parentLocationId)
            .map(d => ({
                label: d.name,
                value: d.id,
            }));
        parentOpts.sort((a, b) => typescriptNaturalSort(a.label, b.label));
        return parentOpts;
    }, [locationsQuery.data]);

    /** The name of the currently selected parent location */
    const parentLocationName = useMemo(() => {
        return parentLocationOptions.find(l => l.value === editModel.parentLocationId)?.label;
    }, [editModel.parentLocationId, parentLocationOptions]);

    const handleWarehouseChanged = useCallback(
        (val: string) => {
            updateField({ parentLocationId: val });
            dispatch(setPrimaryLocationId(val));
        },
        [dispatch, updateField],
    );

    return (
        <>
            <PropertyContainer>
                {parentLocationOptions && (
                    <PropertyEditSelect
                        label="Warehouse"
                        value={editModel.parentLocationId}
                        onChange={handleWarehouseChanged}
                        readonly={!isEditing}
                        disabled={isSaving}
                        autoFocus={true}
                        options={parentLocationOptions}
                        validationRequired={true}
                        fullWidth={true}
                    />
                )}

                <PropertyEditText
                    label="Notes"
                    multiline={true}
                    value={editModel.notes}
                    onChange={val => updateField({ notes: val })}
                    readonly={!isEditing}
                    disabled={isSaving}
                />

                <PropertyEditSelect
                    label="Stocktake type"
                    value={editModel.stocktakeType}
                    onChange={val => {
                        const stocktakeType =
                            parseEnum(StocktakeType, val) ?? StocktakeType.Complete;
                        updateField({ stocktakeType });
                    }}
                    readonly={!isEditing}
                    disabled={isSaving}
                    options={StocktakeTypeDisplay.options}
                    validationRequired={true}
                    fullWidth={true}
                />

                {editModel.stocktakeType === StocktakeType.Rolling && (
                    <PropertyContainer indent={true}>
                        <PropertyEditSelect
                            label="Count by"
                            value={countBy}
                            onChange={val => {
                                if (val === 'location' || val === 'group') {
                                    setCountBy(val);
                                }
                            }}
                            options={[
                                { value: 'location', label: 'Location' },
                                { value: 'group', label: 'Inventory group' },
                            ]}
                        />

                        {countBy === 'location' ? (
                            <LocationSelector
                                editModel={editModel}
                                isEditing={isEditing}
                                isSaving={isSaving}
                                updateField={updateField}
                                parentLocationName={parentLocationName}
                                setCountBy={setCountBy}
                            />
                        ) : countBy === 'group' ? (
                            <GroupSelector
                                isEditing={isEditing}
                                isSaving={isSaving}
                                editModel={editModel}
                                updateField={updateField}
                            />
                        ) : null}
                    </PropertyContainer>
                )}
            </PropertyContainer>
        </>
    );
}

function LocationSelector({
    editModel,
    isEditing,
    isSaving,
    updateField,
    parentLocationName,
    setCountBy,
}: {
    editModel: StocktakeCreate;
    isEditing: boolean;
    isSaving: boolean;
    updateField: (data: Partial<StocktakeCreate>) => void;
    parentLocationName?: string;
    setCountBy: (val: 'location' | 'group') => void;
}) {
    const [childLocationNodes, selectedLocationIds] = useLocationNodes({
        parentLocationId: editModel?.parentLocationId,
        targets: editModel.targets,
    });

    return (
        <PropertyContainer>
            <FieldValidator
                value={editModel.targets}
                validationCustom={
                    selectedLocationIds &&
                    selectedLocationIds.length === 0 &&
                    'Please select at least one location'
                }
            >
                {childLocationNodes.length ? (
                    <MyCheckboxTree
                        className="StocktakeCreateModal__CheckboxTree"
                        nodes={childLocationNodes}
                        selectedIds={selectedLocationIds}
                        readonly={!isEditing || isSaving}
                        onChange={ids =>
                            updateField({
                                targets: ids.map(id => ({
                                    targetId: id,
                                    targetType: StocktakeTargetType.Location,
                                })),
                            })
                        }
                    />
                ) : (
                    <div className="StocktakeCreateModal__CheckboxTreeEmpty">
                        {parentLocationName} doesn't contain any sub-locations. You can still count
                        by{' '}
                        <MyButton
                            buttonType="LinkButton"
                            onClick={() => setCountBy('group')}
                            label="Inventory group"
                        />{' '}
                        or select Stocktake type{' '}
                        <MyButton
                            buttonType="LinkButton"
                            onClick={() => updateField({ stocktakeType: StocktakeType.Complete })}
                            label={StocktakeTypeDisplay.display(StocktakeType.Complete)}
                        />{' '}
                        instead.
                    </div>
                )}
            </FieldValidator>
        </PropertyContainer>
    );
}

function GroupSelector({
    editModel,
    isEditing,
    isSaving,
    updateField,
}: {
    editModel: StocktakeCreate;
    isEditing: boolean;
    isSaving: boolean;
    updateField: (data: Partial<StocktakeCreate>) => void;
}) {
    const groupsQuery = inventoryApi.useInventoryGroupListQuery();

    const [groupNodes, selectedInventoryGroupIds] = useGroupNodes({ targets: editModel.targets });

    return (
        <PropertyContainer>
            <FieldValidator
                value={editModel.targets}
                validationCustom={
                    selectedInventoryGroupIds &&
                    selectedInventoryGroupIds.length === 0 &&
                    'Please select at least one inventory group'
                }
            >
                {groupsQuery.isLoading ? (
                    <MyLinearProgress delay={0} />
                ) : groupsQuery.isError ? (
                    <ErrorContent />
                ) : !groupNodes?.length ? (
                    <div className="StocktakeCreateModal__CheckboxTreeEmpty">No groups found.</div>
                ) : (
                    <MyCheckboxTree
                        className="StocktakeCreateModal__CheckboxTree"
                        nodes={groupNodes}
                        selectedIds={selectedInventoryGroupIds}
                        readonly={!isEditing || isSaving}
                        onChange={ids =>
                            updateField({
                                targets: ids.map(id => ({
                                    targetId: id,
                                    targetType: StocktakeTargetType.InventoryGroup,
                                })),
                            })
                        }
                    />
                )}
            </FieldValidator>
        </PropertyContainer>
    );
}
