import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditDateTime from 'components/PropertyEditDateTime/PropertyEditDateTime';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import dispatchApi from 'features/dispatch/dispatch.api';
import { ShipmentEntity } from 'features/dispatch/models/ShipmentEntity';
import React from 'react';
import './ShipmentEditModal.scss';

export default function ShipmentEditModal({
    model,
    close,
}: {
    model?: ShipmentEntity;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = dispatchApi.useShipmentUpdateMutation();

    const save = async (editModel: ShipmentEntity) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="ShipmentEditModal"
            model={model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit shipment"
            titleContext={model?.tuid}
        >
            {({ editModel, updateField, isSaving }) => (
                <PropertyContainer>
                    <PropertyEditText
                        label="Name"
                        value={editModel.name}
                        onChange={name => updateField({ name })}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                    <PropertyEditDateTime
                        label="Date"
                        value={editModel.date}
                        onChange={date => updateField({ date })}
                        disabled={isSaving}
                        defaultTime="09:00"
                        validationRequired={true}
                    />
                    <PropertyEditNumber
                        label="Shipment days"
                        hint="Estimated number of days in transit"
                        value={editModel.shipmentDays}
                        onChange={shipmentDays => updateField({ shipmentDays })}
                        min={0}
                        allowBlank={false}
                        disabled={isSaving}
                    />
                    <PropertyEditText
                        label="Notes"
                        value={editModel.notes}
                        onChange={notes => updateField({ notes })}
                        multiline={true}
                        disabled={isSaving}
                    />
                </PropertyContainer>
            )}
        </MyEditModal>
    );
}
