import Icons from 'Icons';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import MySelectInput from 'components/MySelectInput/MySelectInput';
import MyTabs from 'components/MyTabs/MyTabs';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import WorkflowBreakdownConditionType, {
    WorkflowBreakdownConditionTypeDisplay,
} from 'features/workOrders/enums/WorkflowBreakdownConditionType';
import WorkflowBreakdownFieldSource, {
    WorkflowBreakdownFieldSourceDisplay,
} from 'features/workOrders/enums/WorkflowBreakdownFieldSource';
import WorkflowBreakdownNonMappedKeys, {
    WorkflowBreakdownNonMappedKeysDisplay,
} from 'features/workOrders/enums/WorkflowBreakdownNonMappedKeys';
import {
    WorkflowItemSplitCondition,
    WorkflowItemSplitConfig,
    WorkflowItemSplitField,
    WorkflowItemSplitMapping,
} from 'features/workOrders/models/WorkflowItemSplitConfig';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { isEmpty, parseEnum } from 'utils/helpers';
import './WorkflowItemSplitEditModal.scss';

export default function WorkflowItemSplitEditModal({
    model,
    close,
    onSave,
}: {
    model: WorkflowItemSplitConfig;
    close: () => void;
    onSave?: (editModel: WorkflowItemSplitConfig) => Promise<any | void>;
}) {
    const save = useCallback(
        async (editModel: WorkflowItemSplitConfig) => {
            await onSave?.(editModel);
            close();
        },
        [close, onSave],
    );

    return (
        <MyEditModal
            className="WorkflowItemSplitEditModal"
            model={model}
            editImmediately={true}
            close={close}
            onSave={save}
            title="Edit Item Split"
            disableBackgroundClose={true}
        >
            {({ editModel, updateField, isSaving }) => (
                <EditForm
                    editModel={editModel}
                    updateField={updateField}
                    isSaving={isSaving}
                />
            )}
        </MyEditModal>
    );
}

function EditForm({
    editModel,
    updateField,
    isSaving,
}: {
    editModel: WorkflowItemSplitConfig;
    updateField: (data: Partial<WorkflowItemSplitConfig>) => void;
    isSaving: boolean;
}) {
    const [tab, setTab] = useState('conditions');

    const tabConfigs = useMemo(
        () => [
            {
                name: 'conditions',
                label: `Conditions (${editModel.conditions.length})`,
                content: (
                    <ConditionsTab
                        editModel={editModel}
                        updateField={updateField}
                        isSaving={isSaving}
                    />
                ),
            },
            {
                name: 'globals',
                label: `Globals (${editModel.settings.globals.length})`,
                content: (
                    <GlobalsTab
                        editModel={editModel}
                        updateField={updateField}
                        isSaving={isSaving}
                    />
                ),
            },
            {
                name: 'mappings',
                label: `Mappings (${editModel.mapping.length})`,
                content: (
                    <MappingsTab
                        editModel={editModel}
                        updateField={updateField}
                        isSaving={isSaving}
                    />
                ),
            },
        ],
        [editModel, isSaving, updateField],
    );

    if (!editModel) {
        return null;
    }

    return (
        <div className="WorkflowItemSplitEditModal__EditForm">
            <PropertyContainer layout="table">
                <PropertyEditText
                    label="Type"
                    value={editModel.type}
                    onChange={type => updateField({ type })}
                    validationRequired={true}
                    disabled={isSaving}
                />

                <PropertyEditSelect
                    label="Non-mapped keys"
                    value={editModel.settings.nonMappedKeys}
                    options={WorkflowBreakdownNonMappedKeysDisplay.options}
                    onChange={val => {
                        const nonMappedKeys =
                            parseEnum(WorkflowBreakdownNonMappedKeys, val) ??
                            WorkflowBreakdownNonMappedKeys.Omit;
                        updateField({
                            settings: { ...editModel.settings, nonMappedKeys },
                        });
                    }}
                    disabled={isSaving}
                />
            </PropertyContainer>

            <MyTabs
                className="WorkflowItemSplitEditModal__Tabs"
                activeTab={tab}
                setActiveTab={setTab}
                renderInactiveTabs={true}
                tabs={tabConfigs}
            />
        </div>
    );
}

function ConditionsTab({
    editModel,
    updateField,
    isSaving,
}: {
    editModel: WorkflowItemSplitConfig;
    updateField: (data: Partial<WorkflowItemSplitConfig>) => void;
    isSaving: boolean;
}) {
    const addCondition = useCallback(() => {
        if (!editModel) {
            return;
        }
        const newCond: WorkflowItemSplitCondition = {
            type: WorkflowBreakdownConditionType.Match,
            source: WorkflowBreakdownFieldSource.Option,
            key: '',
            value: '',
        };

        updateField({ conditions: [...editModel.conditions, newCond] });
    }, [editModel, updateField]);

    return (
        <>
            <div className="WorkflowItemSplitEditModal__InputTable">
                {editModel.conditions.length === 0 ? (
                    <div className="WorkflowItemSplitEditModal__InputTable__Empty">
                        No conditions configured
                    </div>
                ) : (
                    <FieldValidator
                        value={JSON.stringify(editModel.conditions)}
                        validationCustom={
                            editModel.conditions.length < 1 && 'At least one condition is required'
                        }
                    >
                        {editModel.conditions.map((cond, i) => (
                            <ConditionRow
                                key={i}
                                index={i}
                                condition={cond}
                                isSaving={isSaving}
                                updateField={(data: Partial<WorkflowItemSplitCondition>) => {
                                    const conditions = [...editModel.conditions];
                                    conditions[i] = { ...editModel.conditions[i], ...data };
                                    updateField({ conditions });
                                }}
                                onRemove={() => {
                                    const conditions = [...editModel.conditions];
                                    conditions.splice(i, 1);
                                    updateField({ conditions });
                                }}
                            />
                        ))}
                    </FieldValidator>
                )}
            </div>
            <MyButton
                label="Add Condition"
                IconLeft={Icons.Plus}
                buttonType="Hollow"
                onClick={addCondition}
                disabled={isSaving}
            />
        </>
    );
}

function GlobalsTab({
    editModel,
    updateField,
    isSaving,
}: {
    editModel: WorkflowItemSplitConfig;
    updateField: (data: Partial<WorkflowItemSplitConfig>) => void;
    isSaving: boolean;
}) {
    const addField = useCallback(() => {
        const newField: WorkflowItemSplitField = {
            source: WorkflowBreakdownFieldSource.Option,
            key: '',
            newKey: '',
            value: '',
        };
        const globals = [...editModel.settings.globals, newField];
        const settings = { ...editModel.settings, globals };
        updateField({ settings });
    }, [editModel.settings, updateField]);

    const showWarning = editModel.settings.nonMappedKeys === WorkflowBreakdownNonMappedKeys.Append;

    return (
        <>
            {showWarning && (
                <MessagePanel
                    className="WorkflowItemSplitEditModal__GlobalsTab__Warning"
                    messageType="warning"
                >
                    Globals are disabled when <strong>Non-mapped keys</strong> is set to{' '}
                    <strong>Append</strong>
                </MessagePanel>
            )}

            <div className="WorkflowItemSplitEditModal__InputTable">
                {editModel.settings.globals.length === 0 ? (
                    <div className="WorkflowItemSplitEditModal__InputTable__Empty">
                        No globals configured
                    </div>
                ) : (
                    <FieldValidator value={JSON.stringify(editModel.settings.globals)}>
                        {editModel.settings.globals.map((f, i) => (
                            <FieldRow
                                key={i}
                                index={i}
                                field={f}
                                isSaving={isSaving}
                                updateField={(data: Partial<WorkflowItemSplitField>) => {
                                    const globals = [...editModel.settings.globals];
                                    globals[i] = { ...editModel.settings.globals[i], ...data };
                                    const settings = { ...editModel.settings, globals };
                                    updateField({ settings });
                                }}
                                onRemove={() => {
                                    const globals = [...editModel.settings.globals];
                                    globals.splice(i, 1);
                                    const settings = { ...editModel.settings, globals };
                                    updateField({ settings });
                                }}
                            />
                        ))}
                    </FieldValidator>
                )}
            </div>
            <MyButton
                label="Add Global"
                IconLeft={Icons.Plus}
                buttonType="Hollow"
                onClick={addField}
                disabled={isSaving}
            />
        </>
    );
}

function MappingsTab({
    editModel,
    updateField,
    isSaving,
}: {
    editModel: WorkflowItemSplitConfig;
    updateField: (data: Partial<WorkflowItemSplitConfig>) => void;
    isSaving: boolean;
}) {
    const addMapping = useCallback(() => {
        if (!editModel) {
            return;
        }
        const newMapping: WorkflowItemSplitMapping = {
            id: '',
            type: '',
            fields: [
                {
                    key: '',
                    newKey: '',
                    source: WorkflowBreakdownFieldSource.Option,
                    value: '',
                },
            ],
        };

        updateField({ mapping: [...editModel.mapping, newMapping] });
    }, [editModel, updateField]);

    return (
        <>
            <FieldValidator value={JSON.stringify(editModel.mapping)}>
                {editModel.mapping.map((m, i) => (
                    <MappingSection
                        key={i}
                        index={i}
                        mapping={m}
                        isSaving={isSaving}
                        updateField={(data: Partial<WorkflowItemSplitMapping>) => {
                            const mapping = [...editModel.mapping];
                            mapping[i] = { ...editModel.mapping[i], ...data };
                            updateField({ mapping });
                        }}
                        onRemove={() => {
                            const mapping = [...editModel.mapping];
                            mapping.splice(i, 1);
                            updateField({ mapping });
                        }}
                    />
                ))}
            </FieldValidator>

            <MyButton
                label="Add Mapping"
                IconLeft={Icons.Plus}
                onClick={addMapping}
                disabled={isSaving}
            />
        </>
    );
}

function ConditionRow({
    index,
    condition,
    isSaving,
    updateField,
    onRemove,
}: {
    index: number;
    condition: WorkflowItemSplitCondition;
    isSaving: boolean;
    updateField: (data: Partial<WorkflowItemSplitCondition>) => void;
    onRemove: () => void;
}) {
    return (
        <div
            className="WorkflowItemSplitEditModal__InputRow"
            key={index}
        >
            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MySelectInput
                    value={condition.source}
                    options={WorkflowBreakdownFieldSourceDisplay.options.filter(
                        o =>
                            o.value === WorkflowBreakdownFieldSource.Option ||
                            o.value === WorkflowBreakdownFieldSource.Label,
                    )}
                    handleInput={val => {
                        const source =
                            parseEnum(WorkflowBreakdownFieldSource, val) ??
                            WorkflowBreakdownFieldSource.Option;
                        updateField({ source });
                    }}
                    disabled={isSaving}
                    fullWidth
                />
            </div>
            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MyTextInput
                    label=""
                    value={condition.key}
                    handleInput={key => updateField({ key })}
                    validationRequired="Condition requires a key"
                    disabled={isSaving}
                    fullWidth
                />
            </div>
            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MySelectInput
                    label=""
                    value={condition.type}
                    options={WorkflowBreakdownConditionTypeDisplay.options}
                    handleInput={val => {
                        const type =
                            parseEnum(WorkflowBreakdownConditionType, val) ??
                            WorkflowBreakdownConditionType.Match;
                        updateField({ type });
                    }}
                    disabled={isSaving}
                    fullWidth
                />
            </div>
            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MyTextInput
                    label=""
                    value={condition.value ?? ''}
                    handleInput={value => updateField({ value })}
                    validationRequired="Condition requires a value"
                    disabled={isSaving}
                    fullWidth
                />
            </div>
            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MyButton
                    className="WorkflowItemSplitEditModal__InputRow__RemoveButton"
                    IconLeft={Icons.Close}
                    buttonType="Nude"
                    size="small"
                    onClick={onRemove}
                    title="Remove condition"
                    disabled={isSaving}
                />
            </div>
        </div>
    );
}

function MappingSection({
    index,
    mapping,
    isSaving,
    updateField,
    onRemove,
}: {
    index: number;
    mapping: WorkflowItemSplitMapping;
    isSaving: boolean;
    updateField: (data: Partial<WorkflowItemSplitMapping>) => void;
    onRemove: () => void;
}) {
    const addField = useCallback(() => {
        const newField: WorkflowItemSplitField = {
            source: WorkflowBreakdownFieldSource.Option,
            key: '',
            newKey: '',
            value: '',
        };
        updateField({ fields: [...mapping.fields, newField] });
    }, [mapping.fields, updateField]);

    const dialogManager = useDialogManager();
    const handleRemove = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            message: `Are you sure you want to remove this mapping?`,
            acceptLabel: 'Yes, remove',
            acceptButtonType: 'Danger',
        });

        if (confirm) {
            onRemove();
        }
    }, [dialogManager, onRemove]);

    return (
        <div
            className="WorkflowItemSplitEditModal__MappingSection"
            key={index}
        >
            <div className="WorkflowItemSplitEditModal__MappingSection__TopContainer">
                <div>
                    <MyTextInput
                        label="ID"
                        value={mapping.id}
                        onChange={e => updateField({ id: e.target.value })}
                        validationRequired={true}
                        disabled={isSaving}
                    />
                </div>
                <div>
                    <MyTextInput
                        label="Type"
                        value={mapping.type}
                        onChange={e => updateField({ type: e.target.value })}
                        validationRequired={true}
                        disabled={isSaving}
                    />
                </div>
            </div>

            <MyButton
                className="WorkflowItemSplitEditModal__MappingSection__RemoveButton"
                IconLeft={Icons.Close}
                buttonType="Danger"
                title="Remove mapping"
                onClick={handleRemove}
                disabled={isSaving}
            />

            <div className="WorkflowItemSplitEditModal__InputTable">
                {mapping.fields.length === 0 ? (
                    <div className="WorkflowItemSplitEditModal__InputTable__Empty">
                        No fields configured
                    </div>
                ) : (
                    mapping.fields.map((f, i) => (
                        <FieldRow
                            key={i}
                            index={i}
                            field={f}
                            isSaving={isSaving}
                            updateField={(data: Partial<WorkflowItemSplitField>) => {
                                const fields = [...mapping.fields];
                                fields[i] = { ...mapping.fields[i], ...data };
                                updateField({ fields });
                            }}
                            onRemove={() => {
                                const fields = [...mapping.fields];
                                fields.splice(i, 1);
                                updateField({ fields });
                            }}
                        />
                    ))
                )}
            </div>
            <MyButton
                label="Add Field"
                IconLeft={Icons.Plus}
                buttonType="Hollow"
                size="small"
                onClick={addField}
                disabled={isSaving}
            />
        </div>
    );
}

function FieldRow({
    index,
    field,
    isSaving,
    updateField,
    onRemove,
}: {
    index: number;
    field: WorkflowItemSplitField;
    isSaving: boolean;
    updateField?: (data: Partial<WorkflowItemSplitField>) => void;
    onRemove?: () => void;
}) {
    return (
        <div
            className="WorkflowItemSplitEditModal__InputRow"
            key={index}
        >
            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MySelectInput
                    label="Source"
                    value={field.source}
                    options={WorkflowBreakdownFieldSourceDisplay.options}
                    handleInput={val => {
                        const source =
                            parseEnum(WorkflowBreakdownFieldSource, val) ??
                            WorkflowBreakdownFieldSource.Option;
                        updateField?.({ source, value: undefined, newKey: undefined });
                    }}
                    disabled={isSaving}
                    fullWidth
                />
            </div>
            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MyTextInput
                    label="Key"
                    value={field.key}
                    handleInput={key => updateField?.({ key })}
                    disabled={isSaving}
                    validationRequired="Key is required"
                    fullWidth
                    shrinkLabel
                />
            </div>
            {field.source === WorkflowBreakdownFieldSource.Option ||
            field.source === WorkflowBreakdownFieldSource.Label ? (
                <>
                    <div
                        className="WorkflowItemSplitEditModal__InputRow__Cell WorkflowItemSplitEditModal__InputRow__Cell--icon"
                        title="Map value to new key"
                    >
                        &rarr;
                    </div>
                    <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                        <MyTextInput
                            label="New key"
                            value={field.newKey ?? ''}
                            placeholder={field.key ?? ''}
                            handleInput={val => {
                                const valTrimmed = val.trim();
                                updateField?.({
                                    newKey: isEmpty(valTrimmed) ? undefined : valTrimmed,
                                });
                            }}
                            disabled={isSaving}
                            fullWidth
                            shrinkLabel
                        />
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="WorkflowItemSplitEditModal__InputRow__Cell WorkflowItemSplitEditModal__InputRow__Cell--icon"
                        title="Assign fixed value"
                    >
                        =
                    </div>
                    <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                        <MyTextInput
                            label="Value"
                            value={field.value ?? ''}
                            handleInput={value => updateField?.({ value })}
                            disabled={isSaving}
                            fullWidth
                            shrinkLabel
                        />
                    </div>
                </>
            )}

            <div className="WorkflowItemSplitEditModal__InputRow__Cell">
                <MyButton
                    className="WorkflowItemSplitEditModal__InputRow__RemoveButton"
                    IconLeft={Icons.Close}
                    buttonType="Nude"
                    size="small"
                    onClick={onRemove}
                    title="Remove field"
                    disabled={isSaving}
                />
            </div>
        </div>
    );
}
