import z from 'zod';

export const SalesItemOptionsSchema = z.object({
    measurements: z
        .object({
            width: z.number().nullable().optional(),
            height: z.number().nullable().optional(),
        })
        .nullable()
        .optional(),
    options: z
        .array(
            z.object({
                name: z.string().nullable(),
                value: z.union([z.string(), z.number()]).nullable(),
            }),
        )
        .nullable()
        .optional(),
    labels: z
        .array(
            z.object({
                name: z.string(),
                value: z.union([z.string(), z.number()]).nullable(),
            }),
        )
        .nullable(),

    synthesized_options: z.array(
        z.object({
            name: z.string(),
            value: z.union([z.string(), z.number()]).nullable(),
        }),
    ),
});

export type SalesItemOptions = z.infer<typeof SalesItemOptionsSchema>;
