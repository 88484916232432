import React from 'react';
import { isEmpty } from 'utils/helpers';
import './InfoGridTable.scss';

function InfoGridTable({ children }: { children: React.ReactNode }) {
    return <div className="InfoGridTable">{children}</div>;
}

/** Row for displaying option or label variables */
function InfoGridRow({
    label,
    value,
}: {
    label: string;
    value: string | number | React.ReactNode | null;
}) {
    return (
        <div className="InfoGridTable__InfoGridRow">
            <div className="InfoGridTable__InfoGridRow__Label">{label}</div>
            <div className="InfoGridTable__InfoGridRow__Value">
                {isEmpty(value) ? <span className="empty">&ndash;</span> : value}
            </div>
        </div>
    );
}

export { InfoGridRow, InfoGridTable };
