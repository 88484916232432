import z from 'zod';

export const ManufacturerSchema = z.object({
    id: z.number(),
    name: z.string(),
    countryId: z.number(),
    manufacturerStatus: z.string(),
    email: z.string(),
    hostedDomain: z.string().nullable(),
    hostedDomainBackgroundColor: z.string().nullable(),
    hostedDomainLogo: z.string().nullable(),
    hostedDomainNavLogo: z.string().nullable(),
    hostedDomainMobileLogo: z.string().nullable(),
    timezone: z.string(),
});

export type Manufacturer = z.infer<typeof ManufacturerSchema>;
