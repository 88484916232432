import { UdfValueHashSchema } from 'models/UserDefinedField';
import { mergeDeep } from 'utils/helpers';
import z from 'zod';
import ContainerType from '../enums/ContainerType';
import InventoryType from '../enums/InventoryType';

export const InventorySchema = z.object({
    id: z.string(),
    tenantSupplierId: z.string(),
    tenantSupplierName: z.string(),
    attributes: z.unknown(),
    description: z.string(),
    hasSerialNumber: z.boolean(),
    inventoryGroupId: z.string(),
    inventoryGroupName: z.string(),
    inventoryType: z.nativeEnum(InventoryType),
    containerType: z.nativeEnum(ContainerType).default(ContainerType.None),
    maximumQuantityLevel: z.number(),
    minimumQuantityLevel: z.number(),
    notes: z.string(),
    partNumber: z.string(),
    reorderQuantity: z.number().nullable(),
    supplierPartNumber: z.string(),
    userDefinedFields: UdfValueHashSchema,
});

export type Inventory = z.infer<typeof InventorySchema>;

export class InventoryFactory {
    static create(data: Partial<Inventory>) {
        const defaults: Inventory = {
            id: '',
            tenantSupplierId: '',
            tenantSupplierName: '',
            attributes: {},
            description: '',
            hasSerialNumber: false,
            inventoryGroupId: '',
            inventoryGroupName: '',
            inventoryType: InventoryType.NonStock,
            containerType: ContainerType.None,
            maximumQuantityLevel: 0,
            minimumQuantityLevel: 0,
            notes: '',
            partNumber: '',
            reorderQuantity: null,
            supplierPartNumber: '',
            userDefinedFields: {},
        };

        const mergedData = mergeDeep(defaults, data);

        return InventorySchema.parse(mergedData);
    }
}
