import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { api, ApiTagType } from 'services/api';
import { AttributeListResult, AttributeListResultSchema } from './models/AttributeListResult';
import { Inventory, InventorySchema } from './models/Inventory';
import { InventoryDetailResult, InventoryDetailResultSchema } from './models/InventoryDetailResult';
import { InventoryGroupDetail, InventoryGroupDetailSchema } from './models/InventoryGroupDetail';
import {
    InventoryGroupListResult,
    InventoryGroupListResultSchema,
} from './models/InventoryGroupListResult';
import {
    InventoryGroupListWithAttributesResult,
    InventoryGroupListWithAttributesResultSchema,
} from './models/InventoryGroupListWithAttributesResult';
import {
    InventoryGroupSchemaResult,
    InventoryGroupSchemaResultSchema,
} from './models/InventoryGroupSchemaResult';
import {
    InventoryLocationsResult,
    InventoryLocationsResultSchema,
} from './models/InventoryLocationsResult';
import {
    InventoryItemMovement,
    InventoryItemMovementSchema,
} from './models/InventoryMovementBatch';

import { dateFilterOptionsAny, DateFilterValuesPast } from 'models/DateFilterOption';
import z from 'zod';
import InventoryReceiptStatus from './enums/InventoryReceiptStatus';
import { InventoryGroupSummarySchema } from './models/InventoryGroupSummary';
import {
    InventoryLocationJoinDetail,
    InventoryLocationJoinDetailSchema,
} from './models/InventoryLocationJoinDetail';
import { InventoryMovementCreateRequest } from './models/InventoryMovementCreateRequest';
import { InventoryReceipt, InventoryReceiptSchema } from './models/InventoryReceipt';
import {
    InventoryReceiptListResult,
    InventoryReceiptListResultSchema,
} from './models/InventoryReceiptListResult';
import { InventorySchemaResult, InventorySchemaResultSchema } from './models/InventorySchemaResult';
import { InventorySearchResult, InventorySearchResultSchema } from './models/InventorySearchResult';
import { InventoryTotals, InventoryTotalsSchema } from './models/InventoryTotals';
import { LocationDetailResult, LocationDetailResultSchema } from './models/LocationDetailResult';
import { LocationInventory, LocationInventorySchema } from './models/LocationInventory';
import { LocationListResult, LocationListResultSchema } from './models/LocationListResult';
import { SupplierListResult, SupplierListResultSchema } from './models/SupplierListResult';

const inventoryCreateResultSchema = z.object({
    data: InventorySchema,
});

export type InventorySearchParams = DynamicQueryParams<{
    search: string;
    inventoryGroupId: string;
}>;

export type InventoryReceiptListParams = DynamicQueryParams<{
    search: string; // search by tuid
    locationId: string | null; // always a top-level parent location id
    dateRange: DateFilterValuesPast | null;
    status: InventoryReceiptStatus | null;
    isArchived: boolean | null;
}>;

function buildSearchCriteria(params: InventorySearchParams) {
    const searchObj = params.criteria?.search
        ? {
              type: 'group',
              operator: 'OR',
              left: {
                  type: 'group',
                  left: {
                      type: 'string',
                      propertyKey: 'PARTNUMBER',
                      value: params.criteria.search,
                      equality: 'CONTAINS',
                  },
                  right: {
                      type: 'string',
                      propertyKey: 'SUPPLIERPARTNUMBER',
                      value: params.criteria.search,
                      equality: 'CONTAINS',
                  },
                  operator: 'OR',
              },
              right: {
                  type: 'string',
                  propertyKey: 'DESCRIPTION',
                  value: params.criteria.search,
                  equality: 'CONTAINS',
              },
          }
        : undefined;

    const groupObj = params.criteria.inventoryGroupId
        ? {
              type: 'string',
              propertyKey: 'GROUP',
              value: params.criteria.inventoryGroupId,
              equality: 'EQUALS',
          }
        : undefined;

    const criteria = [searchObj, groupObj].filter(Boolean);
    return criteria;
}

const inventoryApi = api.injectEndpoints({
    endpoints: build => ({
        attributeList: build.query<AttributeListResult, void>({
            query: () => ({
                url: `/attribute`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {},
                },
            }),
            transformResponse: (result: unknown) => AttributeListResultSchema.parse(result),
            providesTags: () => [ApiTagType.AttributesList],
        }),

        inventoryArchive: build.mutation<void, string>({
            query: id => ({
                url: `/inventory/${id}/archive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: (result, err, id) => [
                ApiTagType.InventoryList,
                { type: ApiTagType.InventoryDetail, id },
            ],
        }),

        inventoryCreate: build.mutation<string | undefined, Inventory>({
            query: model => ({
                url: `/inventory/create`,
                method: 'POST',
                data: model,
            }),
            transformResponse: (result: unknown) =>
                // return id of new record
                inventoryCreateResultSchema.parse(result).data.id,
            invalidatesTags: [ApiTagType.InventoryList],
        }),

        inventoryDetail: build.query<InventoryDetailResult, string>({
            query: (id: string) => ({
                url: `/inventory/${id}/detail`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryDetailResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.InventoryDetail, id }],
        }),

        inventoryImport: build.mutation<void, File>({
            async queryFn(file, _queryApi, _extraOptions, fetchWithBQ) {
                const formData = new FormData();
                formData.append('file', file, file.name);
                const response = await fetchWithBQ({
                    url: `/inventory/import`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.error) {
                    return {
                        error: response.error,
                    };
                }
                return {
                    data: undefined,
                };
            },
        }),

        inventoryLocations: build.query<InventoryLocationsResult, string>({
            query: inventoryId => ({
                url: `/inventory/${inventoryId}/locations`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryLocationsResultSchema.parse(result),
            providesTags: (res, err, inventoryId) => [
                { type: ApiTagType.InventoryLocations, id: inventoryId },
            ],
        }),

        inventorySchema: build.query<InventorySchemaResult, void>({
            query: () => ({
                url: `/inventory/schema`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventorySchemaResultSchema.parse(result),
        }),

        inventorySearch: build.query<InventorySearchResult, InventorySearchParams>({
            query: (params: InventorySearchParams) => ({
                url: '/inventory',
                method: 'POST',
                data: {
                    meta: {
                        limit: params.paging.limit,
                        skip: params.paging.skip,
                        includeArchived: false,
                        ordering: params.sort ? [params.sort] : [],
                        criteria: buildSearchCriteria(params),
                    },
                },
            }),
            transformResponse: (result: unknown) => InventorySearchResultSchema.parse(result),
            providesTags: [ApiTagType.InventoryList],
        }),

        inventoryUpdate: build.mutation<void, Inventory>({
            query: model => ({
                url: `/inventory/update`,
                method: 'POST',
                data: model,
            }),
            transformResponse: () => undefined,
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    inventoryApi.util.updateQueryData('inventoryDetail', model.id, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft.data, model);
                    }),
                );

                dispatch(inventoryApi.util.invalidateTags([ApiTagType.InventoryList]));
            },
        }),

        inventoryTotals: build.query<
            InventoryTotals[],
            { parentLocationId: string; tenantInventoryIds: string[] }
        >({
            query: params => ({
                url: '/inventory/totals',
                method: 'POST',
                data: params,
            }),
            transformResponse: (result: unknown) => z.array(InventoryTotalsSchema).parse(result),
            providesTags: (res, err, params) => [
                { type: ApiTagType.InventoryTotals, id: params.parentLocationId },
            ],
        }),

        inventoryGroupAttributeAdd: build.mutation<void, { groupId: string; attributeId: string }>({
            query: props => ({
                url: `/inventorygroup/${props.groupId}/attributes/add`,
                method: 'POST',
                data: {
                    attributeId: props.attributeId,
                },
            }),
            transformResponse: () => undefined,
            invalidatesTags: (result, err, props) => [
                ApiTagType.InventoryGroupList,
                { type: ApiTagType.InventoryGroupDetail, id: props.groupId },
            ],
        }),

        inventoryGroupAttributeRemove: build.mutation<
            void,
            { groupId: string; attributeId: string }
        >({
            query: props => ({
                url: `/inventorygroup/${props.groupId}/attributes/remove`,
                method: 'POST',
                data: {
                    attributeId: props.attributeId,
                },
            }),
            transformResponse: () => undefined,
            invalidatesTags: (result, err, props) => [
                ApiTagType.InventoryGroupList,
                { type: ApiTagType.InventoryGroupDetail, id: props.groupId },
            ],
        }),

        inventoryGroupArchive: build.mutation<void, string>({
            query: (id: string) => ({
                url: `/inventorygroup/${id}/archive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: (result, err, id) => [
                ApiTagType.InventoryGroupList,
                { type: ApiTagType.InventoryGroupDetail, id },
            ],
        }),

        inventoryGroupCreate: build.mutation<string | undefined, InventoryGroupDetail>({
            query: model => ({
                url: `/inventorygroup/create`,
                method: 'POST',
                data: model,
            }),
            transformResponse: (result: unknown) =>
                // return id of new record
                InventoryGroupSummarySchema.parse(result).id,
            invalidatesTags: [ApiTagType.InventoryGroupList],
        }),

        inventoryGroupDetail: build.query<InventoryGroupDetail, string>({
            query: (id: string) => ({
                url: `/inventorygroup/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryGroupDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.InventoryGroupDetail, id }],
        }),

        inventoryGroupList: build.query<InventoryGroupListResult, void>({
            query: () => ({
                url: `/inventorygroup`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {},
                },
            }),
            transformResponse: (result: unknown) => InventoryGroupListResultSchema.parse(result),
            providesTags: [ApiTagType.InventoryGroupList],
        }),

        inventoryGroupListWithAttributes: build.query<InventoryGroupListWithAttributesResult, void>(
            {
                query: () => ({
                    url: `/inventorygroup/withattributes`,
                    method: 'POST',
                    data: {
                        data: null,
                        meta: {},
                    },
                }),
                transformResponse: (result: unknown) =>
                    InventoryGroupListWithAttributesResultSchema.parse(result),
                providesTags: [ApiTagType.InventoryGroupList],
            },
        ),

        inventoryGroupSchema: build.query<InventoryGroupSchemaResult, void>({
            query: () => ({
                url: `/inventorygroup/schema`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryGroupSchemaResultSchema.parse(result),
            // providesTags: (res, err, id) => [{ type: ApiTagType.InventorySchema, id }],
        }),

        inventoryGroupUpdate: build.mutation<void, InventoryGroupDetail>({
            query: model => ({
                url: `/inventorygroup/${model.id}/update`,
                method: 'POST',
                data: model,
            }),
            transformResponse: () => undefined,
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    inventoryApi.util.updateQueryData('inventoryGroupDetail', model.id, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft, model);
                    }),
                );

                dispatch(inventoryApi.util.invalidateTags([ApiTagType.InventoryGroupList]));
            },
        }),

        /** Get all movements for a single inventory item */
        inventoryItemMovements: build.query<InventoryItemMovement[], string>({
            query: inventoryId => ({
                url: `/inventory/${inventoryId}/movements`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(InventoryItemMovementSchema).parse(result),
            providesTags: (req, res, inventoryId) => [
                { type: ApiTagType.InventoryMovementList, id: inventoryId },
            ],
        }),

        /** Create a new movement for a single inventory item */
        inventoryMovementCreate: build.mutation<void, InventoryMovementCreateRequest>({
            query: data => ({
                url: `/inventorymovement/create`,
                method: 'POST',
                data,
            }),
            invalidatesTags: (result, err, data) => [
                ApiTagType.InventoryMovementList,
                ApiTagType.InventoryTotals,
                ...(data.inventoryMovements?.map(im => ({
                    type: ApiTagType.LocationInventory,
                    id: im.locationId,
                })) ?? []),
                ...(data.inventoryMovements?.map(im => ({
                    type: ApiTagType.InventoryLocations,
                    id: im.tenantInventoryId,
                })) ?? []),
            ],
        }),

        inventoryReceiptList: build.query<InventoryReceiptListResult, InventoryReceiptListParams>({
            query: (params: InventoryReceiptListParams) => {
                let dateReceivedFrom = null;
                let dateReceivedTo = null;
                if (params.criteria.dateRange) {
                    const opt = dateFilterOptionsAny.find(
                        o => o.value === params.criteria.dateRange,
                    );
                    if (opt) {
                        dateReceivedFrom = opt.minDate;
                        dateReceivedTo = opt.maxDate;
                    }
                }
                return {
                    url: '/inventoryreceipt',
                    method: 'POST',
                    data: {
                        search: params.criteria.search,
                        locationId: params.criteria.locationId,
                        dateReceivedFrom,
                        dateReceivedTo,
                        status: params.criteria.status,
                        isArchived: params.criteria.isArchived,
                        meta: {
                            limit: params.paging.limit,
                            skip: params.paging.skip,
                            ordering: params.sort ? [params.sort] : [],
                        },
                    },
                };
            },
            transformResponse: (result: unknown) => InventoryReceiptListResultSchema.parse(result),
            providesTags: () => [ApiTagType.InventoryReceiptList],
        }),

        inventoryReceiptCreate: build.mutation<string | undefined, InventoryReceipt>({
            query: model => ({
                url: `/inventoryreceipt/create`,
                method: 'POST',
                data: {
                    locationId: model.locationId,
                    notes: model.notes,
                    dateReceived: model.dateReceived,
                },
            }),
            transformResponse: (result: unknown) =>
                // return id of new record
                InventoryReceiptSchema.parse(result).id,
            invalidatesTags: [ApiTagType.InventoryReceiptList],
        }),

        inventoryReceiptDetail: build.query<InventoryReceipt, string>({
            query: id => ({
                url: `/inventoryreceipt/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryReceiptSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.InventoryReceiptDetail, id }],
        }),

        inventoryReceiptUpdate: build.mutation<void, InventoryReceipt>({
            query: model => ({
                url: `/inventoryreceipt/update`,
                method: 'POST',
                data: model,
            }),
            transformResponse: () => undefined,
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    inventoryApi.util.updateQueryData('inventoryReceiptDetail', model.id, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft, model);
                    }),
                );

                dispatch(inventoryApi.util.invalidateTags([ApiTagType.InventoryReceiptList]));
            },
        }),

        inventoryReceiptArchive: build.mutation<void, string>({
            query: id => ({
                url: `/inventoryreceipt/${id}/archive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: (result, err, id) => [
                ApiTagType.InventoryReceiptList,
                { type: ApiTagType.InventoryReceiptDetail, id },
            ],
        }),

        inventoryReceiptUnarchive: build.mutation<void, string>({
            query: id => ({
                url: `/inventoryreceipt/${id}/unarchive`,
                method: 'POST',
            }),
            transformResponse: () => undefined,
            invalidatesTags: (result, err, id) => [
                ApiTagType.InventoryReceiptList,
                { type: ApiTagType.InventoryReceiptDetail, id },
            ],
        }),

        locationDetail: build.query<LocationDetailResult, string>({
            query: id => ({
                url: `/location/${id}/detail`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => LocationDetailResultSchema.parse(result),
            providesTags: () => [ApiTagType.LocationDetail],
        }),

        locationInventory: build.query<LocationInventory[], string>({
            query: id => ({
                url: `/inventory/location/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => z.array(LocationInventorySchema).parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.LocationInventory, id }],
        }),

        locationInventoryJoinDetail: build.query<
            InventoryLocationJoinDetail,
            { inventoryId: string; locationId: string }
        >({
            query: args => ({
                url: `/inventory/${args.inventoryId}/location/${args.locationId}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => InventoryLocationJoinDetailSchema.parse(result),
            providesTags: (res, err, args) => [
                {
                    type: ApiTagType.InventoryLocations,
                    id: args.inventoryId,
                },
                {
                    type: ApiTagType.LocationInventory,
                    id: args.locationId,
                },
            ],
        }),

        locationList: build.query<LocationListResult, void>({
            query: () => ({
                url: `/location`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {
                        limit: 9999,
                        skip: 0,
                    },
                },
            }),
            transformResponse: (result: unknown) => LocationListResultSchema.parse(result),
            providesTags: () => [ApiTagType.LocationList],
        }),

        supplierList: build.query<SupplierListResult, void>({
            query: () => ({
                url: `/supplier`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {},
                },
            }),
            transformResponse: (result: unknown) => SupplierListResultSchema.parse(result),
            providesTags: () => [ApiTagType.SupplierList],
        }),
    }),
});

export default inventoryApi;
