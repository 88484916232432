import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import authApi from 'features/auth/auth.api';
import { UserEdit } from 'features/auth/models/UserEdit';
import React from 'react';
import './UserEditModal.scss';

export default function UserEditModal({ model, close }: { model?: UserEdit; close?: () => void }) {
    const [saveMutation, saveMutationState] = authApi.useUserEditMutation();

    const save = async (editModel: UserEdit) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="UserEditModal"
            model={model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit profile"
        >
            {({ editModel, updateField, isSaving }) => (
                <PropertyContainer>
                    <PropertyEditText
                        label="First name"
                        value={editModel.first_name}
                        onChange={first_name => updateField({ first_name })}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                    <PropertyEditText
                        label="Last name"
                        value={editModel.last_name}
                        onChange={last_name => updateField({ last_name })}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                    <PropertyEditText
                        label="Email"
                        value={editModel.email}
                        onChange={email => updateField({ email })}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                </PropertyContainer>
            )}
        </MyEditModal>
    );
}
