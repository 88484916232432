import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import InventoryReceiptStatus from 'features/inventory/enums/InventoryReceiptStatus';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import inventoryApi from 'features/inventory/inventory.api';
import { InventoryReceipt } from 'features/inventory/models/InventoryReceipt';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useState } from 'react';
import { formatDateRelative } from 'utils/dateHelpers';
import { InventoryReceiptEditModal } from '../InventoryReceiptEditModal/InventoryReceiptEditModal';
import InventoryReceiptStatusBadge from '../InventoryReceiptStatusBadge/InventoryReceiptStatusBadge';
import './InventoryReceiptDetailModal.scss';

export default function InventoryReceiptDetailModal({
    model,
    isLoading = false,
    isError = false,
    close,
}: {
    model?: InventoryReceipt;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [archiveQuery] = inventoryApi.useInventoryReceiptArchiveMutation();
    const [unarchiveQuery] = inventoryApi.useInventoryReceiptUnarchiveMutation();
    const dialogManager = useDialogManager();

    const handleArchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            message: 'Are you sure you want to archive this record?',
            acceptLabel: 'Yes, archive it',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(archiveQuery(model.id).unwrap());
            close?.();
        }
    }, [close, archiveQuery, dialogManager, model]);

    const handleUnarchive = useCallback(async () => {
        if (model) {
            await unarchiveQuery(model.id);
        }
    }, [unarchiveQuery, model]);

    const warehouseOptions = useWarehouseOptions();

    const [isEditing, setIsEditing] = useState(false);

    return (
        <MyModal
            className="InventoryReceiptDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            header={
                <PageHeader
                    title="Inventory Receipt"
                    titleContext={model?.tuid}
                >
                    {model?.status === InventoryReceiptStatus.Draft && !model.isArchived && (
                        <>
                            <MyButton
                                label="Edit"
                                IconLeft={Icons.Edit}
                                buttonType="Accent"
                                onClick={() => setIsEditing(true)}
                            />
                        </>
                    )}
                    <MyMenuKebabButton
                        menuItems={[
                            model?.isArchived === false && {
                                label: 'Archive',
                                IconLeft: Icons.Archive,
                                onClick: handleArchive,
                            },
                            model?.isArchived === true && {
                                label: 'Unarchive',
                                IconLeft: Icons.Undo,
                                onClick: handleUnarchive,
                            },
                        ]}
                    />
                </PageHeader>
            }
        >
            {model && (
                <PropertyContainer layout="table">
                    <PropertyDisplay
                        label="Warehouse"
                        value={model.locationId}
                        options={warehouseOptions}
                    />

                    <PropertyDisplay
                        label="Date Received"
                        value={formatDateRelative(model.dateReceived)}
                    />

                    <PropertyDisplay
                        label="Status"
                        value={
                            <InventoryReceiptStatusBadge
                                status={model.status}
                                isArchived={model.isArchived}
                            />
                        }
                    />

                    <PropertyDisplay
                        label="Notes"
                        verticalAlign="top"
                        value={model.notes}
                    />
                </PropertyContainer>
            )}
            {model && isEditing && (
                <InventoryReceiptEditModal
                    model={model}
                    onClose={() => setIsEditing(false)}
                />
            )}
        </MyModal>
    );
}
