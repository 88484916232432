import { ApiTagType, api } from 'services/api';
import z from 'zod';
import { AccountingConnection, AccountingConnectionSchema } from './models/AccountingConnection';

const accountingApi = api.injectEndpoints({
    endpoints: build => ({
        /** Get accounting requests associated with the sales order */
        salesOrderAccounting: build.query<AccountingConnection[], number>({
            query: salesOrderId => ({
                url: `/accounting/connections/salesorder/${salesOrderId}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(AccountingConnectionSchema).parse(result),
            providesTags: (res, err, salesOrderId) => [
                { type: ApiTagType.SalesOrderAccounting, salesOrderId },
            ],
        }),

        /** Create a new accounting connection -> Request a new invoice  */
        salesOrderInvoiceCreate: build.mutation<void, number>({
            query: orderId => ({
                url: `/accounting/invoice-request`,
                method: 'POST',
                data: { orderId },
            }),
            invalidatesTags: (res, err, orderId) => [
                { type: ApiTagType.SalesOrderAccounting, orderId },
            ],
        }),

        /** Create a new accounting connection -> Request a new invoice  */
        accountingConnectionRetry: build.mutation<void, string>({
            query: requestId => ({
                url: `/accounting/retry`,
                method: 'POST',
                data: { requestId },
            }),
            invalidatesTags: () => [ApiTagType.SalesOrderAccounting],
        }),
    }),
});

export default accountingApi;
