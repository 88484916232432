import MyEditModal from 'components/MyEditModal/MyEditModal';
import inventoryApi from 'features/inventory/inventory.api';
import { selectPrimaryLocationId } from 'features/inventory/inventory.slice';
import {
    InventoryReceipt,
    InventoryReceiptFactory,
} from 'features/inventory/models/InventoryReceipt';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { InventoryReceiptEditFields } from '../InventoryReceiptEditFields/InventoryReceiptEditFields';
import './InventoryReceiptCreateModal.scss';

export default function InventoryReceiptCreateModal({ close }: { close?: () => void }) {
    const primaryLocationId = useAppSelector(selectPrimaryLocationId);
    const model = useMemo(
        () =>
            InventoryReceiptFactory.create({
                locationId: primaryLocationId,
            }),
        [primaryLocationId],
    );

    const [saveMutation, saveMutationState] = inventoryApi.useInventoryReceiptCreateMutation();

    const navigate = useNavigate();
    const save = async (editModel: InventoryReceipt) => {
        const id = await saveMutation(editModel).unwrap();
        navigate(`/receiving/${id}`);
    };

    return (
        <MyEditModal
            className="InventoryReceiptCreateModal"
            model={model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="New inventory receipt"
        >
            {({ editModel, updateField, isSaving }) => (
                <InventoryReceiptEditFields
                    model={editModel}
                    isSaving={isSaving}
                    updateField={updateField}
                />
            )}
        </MyEditModal>
    );
}
