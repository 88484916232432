import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { InventoryMovementBatchTypeDisplay } from 'features/inventory/enums/InventoryMovementBatchType';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import inventoryApi from 'features/inventory/inventory.api';
import { Inventory } from 'features/inventory/models/Inventory';
import { InventoryItemMovement } from 'features/inventory/models/InventoryMovementBatch';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import InventoryMovementCreateDialog from '../InventoryMovementCreateDialog/InventoryMovementCreateDialog';
import './InventoryDetailsMovements.scss';

const COLUMNS = ColumnBuilder<InventoryItemMovement>()
    .column({
        label: 'Date',
        key: 'date',
        isSortable: true,
        defaultSort: 'DESC',
        getValue: item => item.date,
        renderValue: val => formatDateTimeRelative(val),
    })
    .column({
        label: 'Warehouse',
        key: 'warehouseName',
        isSortable: true,
        getValue: item => item.warehouseName,
    })
    .column({
        label: 'Location',
        key: 'locationName',
        isSortable: true,
        getValue: item => item.locationName,
    })
    .column({
        label: 'Batch',
        key: 'batchNumber',
        isSortable: true,
        getValue: item => item.batchNumber,
    })
    .column({
        label: 'Type',
        key: 'movementType',
        getValue: item => InventoryMovementBatchTypeDisplay.display(item.movementType),
        isSortable: true,
    })
    .column({
        label: 'Quantity',
        key: 'quantity',
        isSortable: true,
        getValue: item => item.quantity,
        renderValue: val =>
            val < 0 ? (
                <span className="negative">{val}</span>
            ) : (
                <span className="positive">+{val}</span>
            ),
    })
    .column({
        label: 'Notes',
        key: 'notes',
        isSortable: true,
        getValue: item => item.notes,
        renderValue: val => <span title={val}>{val}</span>,
    })
    .build();

export default function InventoryDetailsMovements({ model }: { model?: Inventory }) {
    const [filteredData, setFilteredData] = useState<InventoryItemMovement[]>();
    const query = inventoryApi.useInventoryItemMovementsQuery(model?.id || '', {
        skip: !model?.id,
    });

    const warehouseFilterOptions = useWarehouseOptions();

    const tableFilters: DataTableFilterConfig[] = useMemo(
        () => [
            {
                label: 'Search',
                type: 'search',
                defaultValue: '',
                fields: ['warehouseName', 'locationName', 'batchNumber', 'movementType', 'notes'],
            },
            {
                label: 'Date',
                type: 'date',
                range: 'past',
                defaultValue: '',
                field: 'date',
            },
            {
                label: 'Warehouse',
                type: 'select',
                defaultValue: '',
                options: warehouseFilterOptions,
                field: 'warehouseId',
            },
            {
                label: 'Type',
                type: 'select',
                defaultValue: '',
                options: InventoryMovementBatchTypeDisplay.options,
                field: 'movementType',
            },
        ],
        [warehouseFilterOptions],
    );

    const dialogManager = useDialogManager();
    const addMovement = useCallback(async () => {
        if (model) {
            await dialogManager.custom(InventoryMovementCreateDialog, {
                inventoryId: model.id,
            });
        }
    }, [dialogManager, model]);

    return (
        <div className="InventoryDetailsMovements">
            <div className="InventoryDetailsMovements__FilterBar">
                <DataTableFilters
                    data={query.data}
                    filters={tableFilters}
                    onChange={setFilteredData}
                />
                <MyButton
                    className="InventoryDetailsMovements__FilterBar__AddButton"
                    label="Add Movement"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    onClick={addMovement}
                />
            </div>
            {query.isLoading ? (
                // Loading state
                <div className="InventoryDetailsModal__TabLoadingPanel">
                    <MyLinearProgress />
                </div>
            ) : query.isError ? (
                // Error state
                <ErrorContent />
            ) : (
                <DataTable
                    className="InventoryDetailsMovements__DataTable"
                    isLoading={query.isLoading}
                    isError={query.isError}
                    data={filteredData}
                    onRefresh={query.refetch}
                    // rowLinkTo={item => item.id}
                    zebra={true}
                    useFrontEndSorting={true}
                    isRefreshing={query.isFetching}
                    columns={COLUMNS}
                    emptyState="No stock movements found"
                />
            )}
        </div>
    );
}
