import Icons from 'Icons';
import { MyAutocompleteInputOption } from 'components/MyAutocompleteInput/MyAutocompleteInput';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import MyTabs from 'components/MyTabs/MyTabs';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import PropertyEditMoney from 'components/PropertyEditMoney/PropertyEditMoney';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import YesNoEnum, { YesNoEnumDisplay } from 'enums/YesNoEnum';
import customersApi from 'features/customers/customers.api';
import CustomerAccessType from 'features/customers/enums/CustomerAccessType';
import CustomerHoldStatus, {
    CustomerHoldStatusDisplay,
} from 'features/customers/enums/CustomerHoldStatus';
import CustomerPaymentMethod, {
    CustomerPaymentMethodDisplay,
} from 'features/customers/enums/CustomerPaymentMethod';
import { useBrandOptions } from 'features/customers/hooks/useBrandOptions';
import { Customer } from 'features/customers/models/Customer';
import { Company } from 'features/customers/models/CustomerListOldData';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useMemo } from 'react';
import './CustomerDetailModal.scss';

export default function CustomerDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: Customer;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [tab, setTab] = useUrlQueryState('tab', 'account');

    const [saveQuery, saveQueryState] = customersApi.useCustomerUpdateMutation();
    const [dealerLoginQuery] = customersApi.useLazyCustomerLoginTokenQuery();
    const isSaving = saveQueryState.isLoading;
    const dialogManager = useDialogManager();

    const handleSave = useCallback(
        async (editModel: Customer) => {
            await saveQuery(editModel).unwrap();
        },
        [saveQuery],
    );

    const handleDealerLogin = useCallback(async () => {
        if (model) {
            const url = await dialogManager.showLoadingWhile(dealerLoginQuery(model.id).unwrap());
            window.open(url);
        }
    }, [dealerLoginQuery, dialogManager, model]);

    const canLoginToDealerPortal = model?.accessType === CustomerAccessType.DealerPortal;
    return (
        <MyEditModal
            className="CustomerDetailModal"
            title={'Customer'}
            titleContext={model?.mainCompany.name}
            close={close}
            isLoading={isLoading}
            isError={isError}
            model={model}
            onSave={handleSave}
            isSaving={isSaving}
            withTabs={true}
            readonly={tab !== 'account'}
            headerMenuItems={useMemo(
                () => [
                    canLoginToDealerPortal && {
                        label: 'Login to dealer portal',
                        IconLeft: Icons.ExternalLink,
                        onClick: handleDealerLogin,
                    },
                ],
                [canLoginToDealerPortal, handleDealerLogin],
            )}
        >
            {({ editModel, isEditing, updateField }) => (
                <MyTabs
                    activeTab={tab}
                    setActiveTab={setTab}
                    disabled={isEditing}
                    tabs={[
                        {
                            name: 'account',
                            label: 'Account',
                            content: (
                                <AccountTab
                                    editModel={editModel}
                                    isEditing={isEditing}
                                    updateField={updateField}
                                    isSaving={isSaving}
                                />
                            ),
                        },
                        {
                            name: 'details',
                            label: 'Details',
                            content: <DetailsTab model={model?.mainCompany} />,
                        },
                    ]}
                />
            )}
        </MyEditModal>
    );
}

function AccountTab({
    editModel,
    isEditing = false,
    isSaving = false,
    updateField,
}: {
    editModel: Customer;
    isEditing?: boolean;
    isSaving?: boolean;
    updateField: (data: Partial<Customer>) => void;
}) {
    const brandOptions = useBrandOptions();
    const selectedBrandIds = useMemo(() => editModel.brands.map(b => b.id), [editModel.brands]);

    const handleBrandsChanged = useCallback(
        (opts: MyAutocompleteInputOption[]) => {
            const brands = opts.map(o => ({
                id: o ? parseInt(o.value, 10) : 0,
                name: o?.label ?? '',
            }));
            updateField({ brands });
        },
        [updateField],
    );

    // if internal is false, tax is always applicable
    useEffect(() => {
        if (editModel.isInternal === false) {
            updateField({ isTaxApplicable: true });
        }
    }, [editModel.isInternal, updateField]);

    return (
        <PropertyContainer
            className="CustomerDetailModal__AccountTab"
            withInputSpacing
        >
            <PropertyEditAutocomplete
                label="Brands"
                value={selectedBrandIds}
                onChangeMultiple={handleBrandsChanged}
                options={brandOptions}
                allowMultiple={true}
                readonly={!isEditing}
            />

            <PropertyEditSelect
                label="Hold status"
                value={editModel.status}
                options={CustomerHoldStatusDisplay.options}
                onChange={val => {
                    const status = val as CustomerHoldStatus;
                    updateField({ status });
                }}
                readonly={!isEditing}
                disabled={isSaving}
            />

            <PropertyEditSelect
                label="Payment method"
                value={editModel.paymentMethod}
                options={CustomerPaymentMethodDisplay.options}
                onChange={val => {
                    const paymentMethod = val as CustomerPaymentMethod;
                    updateField({ paymentMethod });
                }}
                readonly={!isEditing}
                disabled={isSaving}
            />

            {editModel.paymentMethod === CustomerPaymentMethod.Account && (
                <PropertyContainer indent={true}>
                    <PropertyEditMoney
                        label="Credit limit"
                        value={editModel.creditLimit ?? undefined}
                        onChange={val => updateField({ creditLimit: val ?? null })}
                        allowBlank={true}
                        readonly={!isEditing}
                        disabled={isSaving}
                    />

                    <PropertyEditText
                        label="Credit terms"
                        value={editModel.creditTerms}
                        onChange={val => updateField({ creditTerms: val })}
                        multiline={true}
                        readonly={!isEditing}
                        disabled={isSaving}
                    />
                </PropertyContainer>
            )}

            <PropertyEditSelect
                label="Internal"
                value={`${editModel.isInternal}`}
                options={YesNoEnumDisplay.options}
                onChange={val => updateField({ isInternal: val === YesNoEnum.Yes })}
                readonly={!isEditing}
                disabled={isSaving}
            />

            {editModel.isInternal && (
                <PropertyContainer indent={true}>
                    <PropertyEditSelect
                        label="Tax applicable"
                        value={`${editModel.isTaxApplicable}`}
                        options={YesNoEnumDisplay.options}
                        onChange={val => updateField({ isTaxApplicable: val === YesNoEnum.Yes })}
                        readonly={!isEditing}
                        disabled={isSaving}
                    />
                </PropertyContainer>
            )}

            <PropertyEditText
                label="Shipping details"
                value={editModel.shippingDetails}
                onChange={val => updateField({ shippingDetails: val })}
                multiline={true}
                readonly={!isEditing}
                disabled={isSaving}
            />

            <PropertyEditText
                label="Notes"
                value={editModel.notes}
                onChange={val => updateField({ notes: val })}
                multiline={true}
                readonly={!isEditing}
                disabled={isSaving}
            />
        </PropertyContainer>
    );
}

function DetailsTab({ model }: { model?: Company }) {
    return (
        <div className="CustomerDetailModal__ClientInfoTab">
            <PropertyContainer>
                <PropertyDisplay
                    label="Company name"
                    value={model?.name}
                />

                <PropertyContainer
                    layout="row"
                    spreadRow
                >
                    <PropertyDisplay
                        label="Email"
                        value={
                            model?.email && (
                                <a
                                    className="Link"
                                    href={`mailto:${model?.email}`}
                                >
                                    {model?.email}
                                </a>
                            )
                        }
                    />

                    <PropertyDisplay
                        label="Phone"
                        value={
                            model?.phone && (
                                <a
                                    className="Link"
                                    href={`tel:${model?.phone}`}
                                >
                                    {model?.phone}
                                </a>
                            )
                        }
                    />
                </PropertyContainer>
                <PropertyDisplay
                    label="Address"
                    value={model?.addresses?.[0]?.full_address}
                />
            </PropertyContainer>
        </div>
    );
}
