import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditMoney from 'components/PropertyEditMoney/PropertyEditMoney';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import FeatureFlag from 'enums/FeatureFlag';
import { useSalesItemStatusOptions } from 'features/sales/hooks/useSalesItemStatusOptions';
import { SalesItem } from 'features/sales/models/SalesItem';
import salesApi from 'features/sales/sales.api';
import React, { useCallback } from 'react';
import { PostHogFeature } from 'utils/posthogHelpers';
import './SalesItemEditModal.scss';

export default function SalesItemEditModal({
    model,
    close,
}: {
    model?: SalesItem;
    close?: () => void;
}) {
    const statusOptions = useSalesItemStatusOptions({ currentId: model?.orderLineStatusId });
    const [updateMutation, updateMutationStatus] = salesApi.useSalesItemUpdateMutation();

    const handleSave = useCallback(
        async (editModel: SalesItem) => {
            await updateMutation({ item: editModel });
            close?.();
        },
        [close, updateMutation],
    );

    const isSaving = updateMutationStatus.isLoading;
    return (
        <MyEditModal
            className="SalesItemEditModal"
            title="Edit Sales Item"
            titleContext={`${model?.lineNumber}`}
            close={close}
            isLoading={!statusOptions}
            // isError={isError}
            model={model}
            onSave={handleSave}
            isSaving={isSaving}
            readonly={false}
            fullHeight={false}
            editImmediately={true}
            containerSelector="#modal-root-top"
        >
            {({ editModel, isEditing, updateField }) => (
                <>
                    <PropertyContainer
                        layout="table"
                        withInputSpacing
                    >
                        <PropertyDisplay
                            label="Description"
                            value={editModel.context.product.name}
                        />

                        <PropertyEditNumber
                            label="Quantity"
                            value={editModel.quantity}
                            onChange={val => updateField({ quantity: val })}
                            min={1}
                            withButtons={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                            allowBlank={true}
                            validationRequired={true}
                        />

                        <PropertyEditSelect
                            label="Status"
                            value={`${editModel.orderLineStatusId}`}
                            onChange={(val: string) => {
                                const orderLineStatusId = parseInt(val, 10);
                                updateField({ orderLineStatusId });
                            }}
                            readonly={!isEditing}
                            options={statusOptions}
                            disabled={isSaving}
                            validationRequired={true}
                        />

                        <PropertyEditText
                            label="Notes"
                            hint="Internal only"
                            value={editModel.notes ?? ''}
                            onChange={val => updateField({ notes: val })}
                            multiline={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        <div className="PropertyContainerSpacer" />

                        <PostHogFeature
                            flag={FeatureFlag.CostPrice}
                            match={true}
                        >
                            <PropertyEditMoney
                                label="Unit cost price"
                                value={editModel.unitCostPrice ?? 0}
                                onChange={val => updateField({ unitCostPrice: val })}
                                min={0}
                                allowBlank={true}
                                readonly={!isEditing}
                                disabled={isSaving}
                            />
                        </PostHogFeature>

                        <PropertyEditMoney
                            label="Unit sell price"
                            value={editModel.unitSellPrice ?? 0}
                            onChange={val => updateField({ unitSellPrice: val })}
                            min={0}
                            allowBlank={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />

                        <PropertyEditMoney
                            label="Unit tax"
                            value={editModel.unitTax ?? 0}
                            onChange={val => updateField({ unitTax: val })}
                            min={0}
                            allowBlank={true}
                            readonly={!isEditing}
                            disabled={isSaving}
                        />
                    </PropertyContainer>
                </>
            )}
        </MyEditModal>
    );
}
