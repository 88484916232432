import Drawer from '@mui/material/Drawer';
import Icons from 'Icons';
import { ReactComponent as QuoteriteIconWithNameSvg } from 'assets/quoterite-icon-with-name.svg';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import FeatureFlag from 'enums/FeatureFlag';
import { useBreakpoints } from 'providers/Breakpoints';
import React from 'react';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { PostHogFeature } from 'utils/posthogHelpers';
import './MainNav.scss';

export default function MainNav({
    isLoading = false,
    isMenuOpen = false,
    closeMenu,
}: {
    isLoading?: boolean;
    isMenuOpen?: boolean;
    closeMenu?: () => void;
}) {
    const breakpoints = useBreakpoints();
    return (
        <Drawer
            className="MainNav"
            variant={breakpoints.isSmallDown ? 'temporary' : 'permanent'}
            anchor="left"
            open={isMenuOpen}
            onClose={closeMenu}
        >
            <QuoteriteIconWithNameSvg className="MainNav__Logo" />

            {isLoading ? (
                <MyLinearProgress className="MainNav__LoadingProgress" />
            ) : (
                <>
                    <NavSection title="Dashboard">
                        <NavLinkItem
                            Icon={Icons.Home}
                            label="Home"
                            to="/"
                        />
                        <AbsLinkItem
                            Icon={Icons.Dashboard}
                            label="Dashboard"
                            to={`${window.MANUFACTURING_UI_URL}/#nav-dashboard`}
                        />
                    </NavSection>

                    <NavSection title="Sales">
                        <NavLinkItem
                            Icon={Icons.SalesOrder}
                            label={
                                <>
                                    Sales <small>New!</small>
                                </>
                            }
                            to="/sales"
                        />
                        <AbsLinkItem
                            Icon={Icons.OrderManagement}
                            label="Order Management"
                            to={`${window.MANUFACTURING_UI_URL}/#nav-orders`}
                        />
                    </NavSection>

                    <NavSection title="Manufacturing">
                        {/* <NavLinkItem
                            Icon={Icons.WorkOrder}
                            label="Work Orders"
                            to="/work-orders"
                        /> */}
                        <NavLinkItem
                            Icon={Icons.Schedule}
                            label="Schedule"
                            to="/schedule"
                        />
                        <NavLinkItem
                            Icon={Icons.Workstation}
                            label="Workstations"
                            to="/workstations"
                        />
                    </NavSection>
                    <PostHogFeature
                        flag={FeatureFlag.Dispatch}
                        match={true}
                    >
                        <NavSection title="Dispatch">
                            <NavLinkItem
                                Icon={Icons.Shipment}
                                label="Shipments"
                                to={`/shipments`}
                            />
                            <NavLinkItem
                                Icon={Icons.Package}
                                label="Packages"
                                to={`/packages`}
                            />
                        </NavSection>
                    </PostHogFeature>
                    <NavSection title="Customers">
                        <NavLinkItem
                            Icon={Icons.Customers}
                            label="Customers"
                            to={`/customers`}
                        />
                        <NavLinkItem
                            Icon={Icons.Documents}
                            label="Documents"
                            to={`/documents`}
                        />
                    </NavSection>
                    <NavSection title="Inventory">
                        <NavLinkItem
                            Icon={Icons.Inventory}
                            label="Inventory"
                            to="/inventory"
                        />
                        {/* <NavLinkItem
                            Icon={Icons.InventoryGroup}
                            label="Inventory Groups"
                            to="/inventorygroups"
                        /> */}
                        <NavLinkItem
                            Icon={Icons.Locations}
                            label="Locations"
                            to="/locations"
                        />
                        <PostHogFeature
                            flag={FeatureFlag.Receiving}
                            match={true}
                        >
                            <NavLinkItem
                                Icon={Icons.InventoryReceipt}
                                label="Receiving"
                                to="/receiving"
                            />
                        </PostHogFeature>
                        <NavLinkItem
                            Icon={Icons.Stocktake}
                            label="Stocktake"
                            to={`/stocktake`}
                        />
                    </NavSection>
                    <NavSection title="Settings">
                        <AbsLinkItem
                            Icon={Icons.Settings}
                            label="Settings"
                            to={`${window.MANUFACTURING_UI_URL}/#nav-settings`}
                        />
                        <AbsLinkItem
                            Icon={Icons.ProductPricing}
                            label="Product Pricing"
                            to={`${window.MANUFACTURING_UI_URL}/#nav-product-settings`}
                        />
                        <AbsLinkItem
                            Icon={Icons.EmployeesAndRoles}
                            label="Employees & Roles"
                            to={`${window.MANUFACTURING_UI_URL}/#nav-employee-and-roles`}
                        />
                    </NavSection>
                </>
            )}
        </Drawer>
    );
}

function NavSection({ title = '', children }: { title: string; children?: React.ReactFragment }) {
    return (
        <ul className="MainNav__NavSection">
            <li className="MainNav__NavSection__Title">{title}</li>
            {children}
        </ul>
    );
}

function NavLinkItem({
    Icon = Icons.List,
    label = '',
    to = '',
}: {
    Icon?: IconType;
    label: React.ReactNode;
    to: string;
}) {
    const location = useLocation();
    const rootPath = `/${location.pathname.split('/')[1]}`;
    const isActive = rootPath === to;

    return (
        <li className="MainNav__NavLinkItem">
            <Link
                className={coalesceClassNames('MainNav__NavLinkItem__Link', isActive && 'active')}
                to={to}
            >
                {Icon && <Icon className="icon" />}
                <span className="label">{label}</span>
            </Link>
        </li>
    );
}

function AbsLinkItem({
    Icon = Icons.List,
    label,
    to = '',
}: {
    Icon?: IconType;
    label: React.ReactNode;
    to: string;
}) {
    return (
        <li className="MainNav__NavLinkItem">
            <a
                className={coalesceClassNames('MainNav__NavLinkItem__Link')}
                href={to}
            >
                {Icon && <Icon className="icon" />}
                <span className="label">{label}</span>
            </a>
        </li>
    );
}
